import React from "react";

// Customizable Area Start
import { createStyles, createTheme, styled, Theme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Avatar, Badge, Box, Button,Card,Dialog,DialogContent, Grid, IconButton, Input, InputAdornment, MenuItem, Paper, Select, Switch, Tab, Tabs, TextField, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
export const configJSON = require("./config");
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Feedback from "../../../blocks/contactus/src/Feedback.web";
import { withTranslation } from 'react-i18next';
import CurrencyPopup from "../../../components/src/CurrencyPopup.web";
import { calendar } from "../../../blocks/analytics/src/assets";
import Calendar from "react-calendar";
import {
   uploadIcon,
   crossIcon,
   pdfImage,
   pngImage,
   arrow,
   arrowSide,
   logg,
   crossIconButton,
   flagSaudi,
   leftA,
} from "./assets";
interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
  }
 
const MainWrapper = styled(Box)(({ theme }) => ({
    zIndex:-1,
    position:"absolute",
    width: "100%",
    height:"100vh",
    overflowY:"scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    [theme.breakpoints.up(900)]:{
      zIndex:0,
      position:"inherit",
      width:'100%'
    },
    "& .root":{flexGrow: 1,
    },
    "& .drawerPaper": { backgroundColor: "#122967",
        width:'315px'
      },
    "& .paper":{marginTop:'45px',
        marginLeft: '34px',
        display: 'flex',
        marginRight: '37px',
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    "& .paper_tab":{marginTop:'45px',
      marginLeft: '34px',
      marginRight: '37px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('xs')]:{
        marginTop: '16px',
        
      },
  },
    "& .first_main_box":{display:'flex',
        padding: '33px 27px',
        width: '100%',
        [theme.breakpoints.down("xs")]:{
          padding: '11px 27px',
        },
        [theme.breakpoints.down(450)]:{
          padding: '7px 11px',
          display: 'flex',
          alignItems: 'center'
        },
    },
    "& .avatarImg":{width: theme.spacing(14.5),height: theme.spacing(14.5),
      [theme.breakpoints.down(450)]:{
        width: theme.spacing(8.5),height: theme.spacing(8.5),
      },
    },
    "& .first_main_box_left":{
      marginLeft:'42px',
    [theme.breakpoints.down(600)]:{
      marginLeft:'20px',
    },
    },
    "& .first_main_box_typo_1":{
      lineHeight: '40px',
        color:'#1E293B',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '30px',
        fontWeight: 700,
        letterSpacing: '-0.005em',
        textAlign: 'left',
        wordBreak:'break-word',
        [theme.breakpoints.down('xs')]:{
          fontSize: '24px',
          color:'#1E293B',
          lineHeight: '32px',
        }
    },
    "& .first_main_box_typo_2":{color:'#334155',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        textAlign: 'left',
        marginTop:'8px',
        [theme.breakpoints.down('xs')]:{
          fontSize: '18px',
          color:'#334155',
          lineHeight: '26px',
          wordBreak:'break-all'
        }
    },
    "& .first_main_box_typo_3":{color:'#334155',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '20px',
        fontWeight: 599,
        lineHeight: '28px',
        textAlign: 'left',
        marginTop:'8px',
        [theme.breakpoints.down('xs')]:{
          fontSize: '16px',
          color:'#334155',
          lineHeight: '24px',
          fontWeight: 400,
        }
    },
    "& .first_main_box_typo_4":{
      color:'#334155',fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '20px',
      fontWeight: 599,
      lineHeight: '28px',
      textAlign: 'left',
      marginTop:'8px',
      [theme.breakpoints.down('xs')]:{
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '16px',
        color:'#334155',
        lineHeight: '24px',
        fontWeight: 400,
      }
  },
  "& .tabContainer":{
    [theme.breakpoints.down('xs')]: {
      width:"87%",
    },
    [theme.breakpoints.down(400)]: {
      width:"90%",
    },
    display:"flex",
     alignItems:"flex-start",
    justifyContent:"flex-start",
    width:"95%",
    marginLeft:33
    },
  "& .appBar": {
    backgroundColor:'#F8FAFC',
    width:"70%",
    color:'#475569',
    borderRadius:'50px',
    boxShadow:'20px',
    display: 'flex',
    marginTop: '43px',
    [theme.breakpoints.down('xs')]: {
      width:"90%",
    backgroundColor:'#FFFFFF',
    borderRadius:'50px',
    boxShadow:'20px',
    display: 'flex',
    marginTop: '16px',
    },
    [theme.breakpoints.down(300)]: {
      padding: theme.spacing(1),
    },
  },
  "& .tabs": {
    flexGrow: 1,
  },
  "& .tabRoot": {
    minWidth: 'auto',
    textTransform:'none',
    color: '#475569',
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    [theme.breakpoints.down('xs')]:{
      fontSize: '18px',
      color:"#292524",
      fontWeight: 700,
      lineHeight: '26px',
    },
    
  },
 

  "$ .tabWrapper": {
    padding: theme.spacing(1),
  },
    "& .indicator": {backgroundColor:'#1948CB',
        
      },
  
    "& .first_main_box_verify":{display: 'flex',
        height: '32px',
        padding: '8px 16px 8px 16px',
        backgroundColor:'#EFF6FF',
        borderRadius:'26px',
        justifyContent: 'center',
        alignItems: 'baseline',
        gap:'4px',
        [theme.breakpoints.down('xs')]:{
          backgroundColor:'transparent',
        },
        [theme.breakpoints.down(450)]:{
          padding:0
        }
    },
    "& .first_main_box_verify_style":{
        color:'#132E79',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'right',
        [theme.breakpoints.between(960,986)]:{
            fontSize: '14.5px',
        },
        [theme.breakpoints.down('xs')]:{
          fontSize: '18px',
          color:'#334155',
          lineHeight: '26px',
          textAlign:'start'
      },

    },
   
   
    "& .editForm_Title_Typo":{
        fontWeight: 700,
        color:'#1E293B',
        fontFamily: 'DIN Next LT Arabic Bold',
        fontSize: '18px',
        lineHeight: '26px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]:{
          fontSize: '16px',
          lineHeight: '24px',
      },

    },
    "& .editForm_Edit_Button_Box":{
        display:'flex',gap:'8px',
        cursor:'pointer'
    },
    "& .editForm_Edit_Button_Typo":{
        color:'#1B4FE4',
        fontFamily: 'DIN Next LT Arabic Bold',fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]:{
          fontSize: '16px',
          lineHeight: '24px',
      },
        
    },
    "& .input_Box":{
      display:'flex',
      flexDirection:'column',
      gap:'2px'


    },
    "& .input_typo_style":{
      color:'#334155',
      fontFamily: 'DIN Next LT Arabic Bold',
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '22px',
    },
 
  "& .textFieldRoot": {
    "& .MuiOutlinedInput-input": {
      padding: '11.5px 8px',
      borderRadius:'8px',
      border: '1px solid #CBD5E1',
      textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color:'#334155'
     
    },
    "& .dateInputField": {
      padding: '11.5px 8px',
      borderRadius:'8px',
      border: '1px solid #CBD5E1',
      textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color:'#334155'
    },
    "& . MuiInput-input MuiInputBase-inputAd":{
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      color:'#334155',
      border:"1px solid red"
    },
    "& .MuiOutlinedInput-root":{
      position:'relative',
      borderRadius:'8px',
    },
    "& .MuiInputBase-root":{
      marginTop:'8px'
    }
 
  },
    "& .selectRoot .MuiOutlinedInput-input": {
      padding: '11.5px 8px',
      border: '1px solid #CBD5E1',
      textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '14px',
      color:'#334155'
    },
    "& .arabic": {
      "& .MuiSelect-iconOutlined":{
          right:  "95%",
          [theme.breakpoints.down(1350)]:{
            right:"91%",
          },
          [theme.breakpoints.down(1075)]:{
            right:"88%",
          },
          [theme.breakpoints.down(600)]:{
            right:"92%",
          },
          [theme.breakpoints.down(400)]:{
            right:"90%",
          },
      },
  },
  "& .english": {
      "& .MuiSelect-iconOutlined":{
          right:  "7px"
      },
  },
  "& .DirectionAr":{
direction: "rtl"
  },
  "& .selectAr":{
    direction: "ltr",
    paddingRight:"2px"
      },
    "& .selectRoot .MuiSelect-iconOutlined":{
      right:"92%"
    },
    "& .selectRoot .MuiSelect-outlined":{
      position:'relative',
      borderRadius:'8px',
    },
    "& .selectRoot.MuiOutlinedInput-root":{
      marginTop:'8px',
      borderRadius: '8px'
    },
    "& .textPhoneNumberFieldRoot" : {marginTop: '8px',
      "& .MuiOutlinedInput-input": {padding: '12.5px 8px',borderRadius:'8px',textAlign: 'start',fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color:'#334155'
       
      },
      "&.MuiOutlinedInput-root":{position:'relative',borderRadius:'8px',
      },
      "& .MuiInputBase-root":{margin: '12px 1px 4px 0px'
      },
      "& .MuiSelect-select.MuiSelect-select":{marginBottom: '7px',backgroundColor: 'rgb(239, 246, 255)',color:'#1E293B',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '4px',
        borderRadius: '2px',
        marginLeft: '-7px'
      },
      "& .selectAr .MuiSelect-select":{marginBottom: '7px',backgroundColor: 'rgb(239, 246, 255)',color:'#1E293B',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '24px',
        textAlign: 'left',
        paddingTop: '4px',
        paddingBottom: '4px',
        borderRadius: '2px',
        paddingRight:"2px",
        paddingLeft:"20px",
      },
     
      "& .MuiSelect-icon":{top: 'calc(50% - 14px)',color: 'rgba(0, 0, 0, 0.54)',right: 0 ,position: 'absolute',
        pointerEvents: 'none'
      },
      "& .selectAr .MuiSelect-icon":{top: 'calc(50% - 14px)',color: 'rgba(0, 0, 0, 0.54)',right: "70%" ,position: 'absolute',
      pointerEvents: 'none'
    },
      

    },
    "& .textPasswordFieldRoot" : {
      textAlign: 'start',
       fontFamily: 'DIN Next LT Arabic Regular',fontSize: '16px',
       fontWeight: 400,
     "& .MuiOutlinedInput-input": {padding: '12.5px 8px',
       borderRadius:'8px',textAlign: 'start',
       fontFamily: 'DIN Next LT Arabic Regular',fontSize: '16px',
       fontWeight: 400,
       lineHeight: '24px',
       color:'#334155'
      
     },
     "& .MuiOutlinedInput-root":{position:'relative',
       borderRadius:'8px',
     },
     "& .MuiInputBase-root":{margin: '12px 1px 4px 0px'
     },
     "& .MuiSelect-select.MuiSelect-select":{marginBottom: '7px',backgroundColor: 'rgb(239, 246, 255)',
       color:'#1E293B',
       fontFamily: 'DIN Next LT Arabic Regular',
       fontSize: '16px',
       fontWeight: 500,
       lineHeight: '24px',
       textAlign: 'left',
       paddingTop: '4px',
       paddingBottom: '4px',
       paddingLeft: '4px',
       borderRadius: '2px',
       marginLeft: '-7px'
     },
    "& .MuiSelect-icon":{top: 'calc(50% - 14px)',
       pointerEvents: 'none',
       color: 'rgba(0, 0, 0, 0.54)',right: 0,
       position: 'absolute',
     },
   },
   "& .MuiButton-root":{
    backgroundColor:'#1B4FE4',
    padding:'10px 16px 10px 16px',
    marginLeft: '8px',
    borderRadius:'8px',
    marginTop:'32px',
    [theme.breakpoints.down('xs')]:{
      marginTop:'0px'
    }
   },
   "& .MuiButton-label":{
    color:'#FFFFFF',
    fontFamily: 'DIN Next LT Arabic Bold',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    display: 'contents',
    textTransform:'none'

   },
   
   "& .inputText": {
    borderRadius: "8px",fontWeight: 400,fontSize: "16px",
  },
 "& .imgCon": { width: "100%" },
 "& .imgBox": {
    border: "1px dashed #B0BACD",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    padding: "1rem 0",
    marginTop: "1rem",
  },
"& .fileStyle" : { display: "none" },
"& .fileupLoad" : { width: "50px" },
  "& .uploadText2" : {
    fontSize: "14px",fontWeight: 400,color: "#64748B",
    fontFamily: "DIN Next LT Arabic Regular",
  },

"& .uploadText1": {
    fontSize: "18px",fontWeight: 700,
    color: "#1E293B",fontFamily: "DIN Next LT Arabic Bold",
  },
 
  "& uploadText2": {
    fontSize: "14px",fontWeight: 400,color: "#64748B",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .imgBox2" : {
    border: "1px solid #B0BACD",color: "#0F172A",
    borderRadius: "8px",display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",justifyContent: "space-between",
    gap: "2rem",padding: "10px 8px 10px 8px",
    background: "#CBD5E1",height: "22px",marginTop: '1rem',
  },
  "& .pdfStyle" : { width: "24px", height: "24px" },

  "& .pdfBox": { display: "flex", alignItems: "center", gap: "10px" },

  "& .MuiInputBase-input.Mui-disabled": {
    opacity: '1',
    backgroundColor: '#eee'
  },
  "& .modelPaper": {
    position: 'absolute',
    width: '600px',
    height: '308px',
    backgroundColor: '#FFFFFF',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  "& .closeButton": {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  "& .MuiDialog-paperWidthSm":{
    maxWidth: '600px',
    borderRadius:'16px'

  },
  "& .MuiBottomNavigation-root":{
    height: '82px',
    display: 'flex',
    justifyContent: 'center'
  },
  "& .MuiBottomNavigationAction-root":{
    padding: '13px 12px 8px'
  },
  "& .MuiBottomNavigationAction-label":{
    marginTop:'8px',
    color:"#FFFFFF",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
  },
  "& .bottomNavLabel":{
    color:"#FFFFFF",
    fontFamily: 'DIN Next LT Arabic Regular',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    
  },
  "& .TabBox":{
    display:'flex',
    height:'48px',
    border:'1px solid grey',
    borderRadius:'50px',
    backgroundColor:"#F8FAFC",
    marginTop:'43px',
    [theme.breakpoints.down('xs')]:{
      marginTop:'16px',
      backgroundColor:"#FFFFFF"
    
    }
  },
  "& .passwordGrid":{
    marginTop:'48px',
    [theme.breakpoints.down('xs')]:{
      marginTop:'34px'
    }
  },
 
  "& .MuiAppBar-colorPrimary":{
    backgroundColor:'transparent'
  },
  "& .MuiPaper-elevation4": {
    boxShadow: 'none'
  },
 
  "& .tabSelected":{
    textTransform:'none',
    color: '#1948CB',
    fontFamily: 'DIN Next LT Arabic Regular',
    letterSpacing: '-0.005em',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]:{
      fontSize: '18px',
      color:"#292524",
      fontWeight: 700,
      lineHeight: '26px',
      textAlign: 'center',
    }
  },
  "& .notify": 
    {display: "flex",justifyContent: "center", alignItems: "center", width: "100%"},
  "& .notification_container": {
    [theme.breakpoints.down(900)]:{
      width:"calc(92% - 40px)",
    },
    [theme.breakpoints.down(750)]:{
      width:"calc(89% - 40px)",
    },
    [theme.breakpoints.down('xs')]:{
      width:"calc(88% - 40px)",
      marginLeft:15
    },
    [theme.breakpoints.down(500)]:{
      width:"calc(85% - 40px)",
    },
    [theme.breakpoints.down(450)]:{
      width:"calc(82% - 40px)",
    },
    [theme.breakpoints.down(360)]:{
      marginLeft:15
    },
    width:"calc(95% - 40px)",
    boxShadow: "rgba(0, 0, 0, 0.06, 0.03)",
    padding: "20px",
    marginTop: "40px",
  },
  "& .notification_box": {
    display: "flex",
    flexDirection: "column",
    gap: "42px",
  },
  "& .all_notify_types": {
    display: "flex",
    justifyContent: "space-between",
    width:"calc(100% - 40px)",
    border:"1px solid #CBD5E1",
    gap:"16px",
    padding:"10px 20px",
    alignItems:"center",
    borderRadius:"8px"
  },
  "& .notification_content":{
    [theme.breakpoints.down('xs')]:{
      fontSize: '16px',
    },
    fontSize:"20px",
    fontFamily:"DIN Next LT Arabic Regular",
    color: "#0F172A"
  },
  "& .general_settings":{
    display: "flex",
    justifyContent: "space-between",
    width:"calc(100% - 40px)",
    border:"1px solid #CBD5E1",
    gap:"16px",
    padding:"15px 20px",
    borderRadius:"8px"
  },
  "& .notify_box":{
    width:"100%",
    gap:"8px",
    display:"flex",
    flexDirection:"column"
  },
  "& .notify_titles":{
    [theme.breakpoints.down('xs')]:{
      fontSize: '16px',
      fontFamily:"DIN Next LT Arabic Bold",
    },
    fontSize:"24px",
    color:"#1C1917",
    fontFamily:"DIN Next LT Arabic Regular"
  },
  "& .account_buttons":{
    display:"flex",
    flexDirection:"row",
    gap:"18px",
  },
  "& .deactive_button":{
    backgroundColor:"#F1F5F9",
    color:"#64748B",
    borderRadius:"8px",
    border:"none",
    padding:"10px 16px",
    fontSize: "16px",
    fontFamily: "DIN Next LT Arabic Bold",
    textAlign: "center"
  },
  "& .logout_box":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    backgroundColor:"#FEE2E2",
    color:"#DC2626",
    borderRadius:"8px",
    padding:"10px 16px",
    gap:"8px"
  },

  "& .logout_button":{
    color:"rgba(220, 38, 38, 1)",
    fontSize:"16px",
    fontFamily:"DIN Next LT Arabic Bold",
    cursor: "pointer"
  },
  "& .MuiTab-root": {
    padding: '0px 0px'
  },
  "& .crossStyle":{
    [theme.breakpoints.up(598)]: {
      display: "none"
    },
  },
  "& .logoutContainer": {
      width: "100%",
      position: "fixed" as "fixed",
      top: 0,
      right: 0,
      height: "100%",
      zIndex: 30,
      display: "flex",
      justifyContent: "center" as "center",
      alignItems: "center",
      background: "rgba(0, 0, 0, 0.6)",
  },
  "& .logoutBox": {
    [theme.breakpoints.down(720)]: {
      width: "90%"
    },
    [theme.breakpoints.down(1200)]: {
      width: "53%",
    },
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      backgroundColor: "#FFF",
      top: 0,
      zIndex: 31,
      border: "2px",
      width: "33%",
      boxShadow:
        "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  "& .logoutBoxDeactive": {
    [theme.breakpoints.down(1800)]: {
      width: "37%",
    },
    [theme.breakpoints.down(1640)]: {
      width: "40%",
    },
    [theme.breakpoints.down(1517)]: {
      width: "43%",
    },
    [theme.breakpoints.down(720)]: {
      width: "90%"
    },
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column" as "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "24px",
      backgroundColor: "#FFF",
      top: 0,
      zIndex: 31,
      border: "2px",
      width: "33%",
      boxShadow:
        "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  "& .textBox": {
    [theme.breakpoints.down(598)]: {
      padding: "16px 24px",
    },
    display: "flex",
    flexDirection: "column",
    gap:"16px",
    padding: "35px 35px 15px 35px",
  },
  "& .surefor": {
    [theme.breakpoints.down(598)]: {
      fontSize: "18px",
    },
    fontSize: "24px",
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#0F172A"
  },
  "& .backfor":{
    [theme.breakpoints.down(598)]: {
      fontSize: "14px",
    },
    fontSize: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
    color: "#1E293B"
  },
  "& .buttonBox": {
    [theme.breakpoints.down(598)]: {
      width: "calc(90% - 40px)",
    },
    width: "calc(100% - 40px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as "center",
    gap: "15px",
    padding: "20px",
    borderTop: "1px solid #E2E8F0",
  },
  "& .cancel": {
      padding: "16px 0 16px 0",
      borderRadius: "8px",
      border: "1.5px solid #1B4FE4",
      background: "#FFFFFF",
      color: "#1B4FE4",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      width: "50%",
    },
    "& .back" : {
        padding: "16px 0 16px 0",
        paddingTop: "16px",
        borderRadius: "8px",
        border: "1px solid #DC2626",
        background: "#FEE2E2",
        fontSize: "16px",
        width: "50%",
        cursor: "pointer",
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center"
      },
      "& .back2" : {
        padding: "16px 0 16px 0",
        paddingTop: "16px",
        borderRadius: "8px",
        border: "1px solid #DC2626",
        background: "#1B4FE4",
        fontSize: "16px",
        width: "50%",
        cursor: "pointer",
        display: "flex",
        gap: "8px",
        justifyContent: "center",
        alignItems: "center"
      },
    "& .logg":{
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#DC2626",
    },
    "& .deactive":{
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      color: "#FFFFFF",
    },
    "& .language": 
    {display: "flex",justifyContent: "center", alignItems: "center", width: "100%"},
    "& .language_container": {
      [theme.breakpoints.down(900)]:{
        width:"calc(92% - 40px)",
      },
      [theme.breakpoints.down(850)]:{
        marginLeft:15,
      },
      [theme.breakpoints.down(650)]:{
        width:"calc(88% - 40px)",
      },
      width:"calc(95% - 40px)",
      boxShadow: "rgba(0, 0, 0, 0.06, 0.03)",
      padding: "20px",
      marginTop: "40px",
      height: '100vh',
      [theme.breakpoints.down('xs')]:{
        height: '80vh'
      }
    },
    "& .language_box": {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    "& .all_language_types": {
      display: "flex",
      justifyContent: "space-between",
      width:"calc(100% - 40px)",
      border:"1px solid #CBD5E1",
      gap:"16px",
      padding:"10px 20px",
      alignItems:"center",
      borderRadius:"8px"
    },
    "& .language_content":{
      [theme.breakpoints.down('xs')]:{
        fontSize: '16px',
        fontWeight:800
      },
      fontSize:"20px",
      fontFamily:"DIN Next LT Arabic Regular",
      color: "#0F172A",
      fontWeight:800
    },
    "& .language_flag":{
      display:'flex',
      alignItems: 'center',
      gap:'12px'
    },
    "& .forMobile":{
      display:"none"
    },
    "& .redIcon": { color: "red" },
    "& .nameInput": {
      [theme.breakpoints.down(598)]: {
        width: "93%",
      },
      width: "89%",
      display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "6px",
    },
    "& .labeltext": {
      display: "flex",
      color: "#334155",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: "0.5rem",
      fontSize: "14px",
      fontWeight: 700,
      fontFamily: "DIN Next LT Arabic Bold",
    },
}))

const IOSSwitch = withStyles((theme) => createStyles({
  root: {
    width: 44,
    height: 27.5,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#1B4FE4',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '3px solid #fff',
    },
  },
  thumb: {
    width: 23,
    height: 23,
  },
  track: {
    borderRadius: 20,
    backgroundColor: "#E2E8F0",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(Switch);

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 44,
      height: 44,
      border: `1.5px solid ${theme.palette.background.paper}`,
      [theme.breakpoints.down(450)]:{
        width: 30,
        height: 30,      },
    },
  }),
)(Avatar);


// Customizable Area End

import UserProfileBasicController, {
  Props,
} from "./UserProfileBasicController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserProfileBasicBlockWeb extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
 // Customizable Area Start

 userSchema = () => {
  return Yup.object().shape({
    firstName : Yup.string().required("Please enter your first name"),
    lastName : Yup.string().required("Please enter your last name"),
    email: Yup.string().email("Enter valid email id").required("Please enter your email"),
    countryName: Yup.string().notOneOf(['none'], 'Please select country name').required('Please select country name'),
    phoneNumber: Yup.string().required('Please enter your phone number').max(11, 'Phone number is not valid').min(7, 'Phone number is too short'),
    investmentService: Yup.string().notOneOf(['none'], 'Please select your investment service').required('Please select your investment service'),
    idProof: Yup.string().notOneOf(['none'], 'Please select your id proof').required('Please select your id proof'),
    file: Yup.string().required("Please upload document"),

  });
};

userSchemaAr = () => {
  return Yup.object().shape({
    firstName: Yup.string().required("يرجى إدخال اسمك الأول"),
    lastName: Yup.string().required("يرجى إدخال اسم العائلة"),
    email: Yup.string().email("يرجى إدخال بريد إلكتروني صحيح").required("يرجى إدخال بريدك الإلكتروني"),
    countryName: Yup.string().notOneOf(['none'], 'يرجى اختيار اسم الدولة').required('يرجى اختيار اسم الدولة'),
    phoneNumber: Yup.string()
      .required('يرجى إدخال رقم هاتفك')
      .max(11, 'رقم الهاتف غير صالح')
      .min(7, 'رقم الهاتف قصير جداً'),
    investmentService: Yup.string()
      .notOneOf(['none'], 'يرجى اختيار خدمة الاستثمار')
      .required('يرجى اختيار خدمة الاستثمار'),
    idProof: Yup.string().notOneOf(['none'], 'يرجى اختيار إثبات الهوية').required('يرجى اختيار إثبات الهوية'),
    file: Yup.string().required("يرجى تحميل المستند"),
  });
};

userPasswordSchema = () => {
  return Yup.object().shape({
    currentPasswordText: Yup.string().required("Please enter your current password"),
    newPasswordText: Yup.string().required("Please enter your new password"),
    reTypePasswordText: Yup.string().required("Please confirm your password"),

  });
};
userPasswordSchemaAr = () => {
  return Yup.object().shape({
    currentPasswordText: Yup.string().required("الرجاء إدخال كلمة المرور الحالية الخاصة بك"),
    newPasswordText: Yup.string().required("الرجاء إدخال كلمة المرور الجديدة"),
    reTypePasswordText: Yup.string().required("يرجى تأكيد كلمة المرور الخاصة بك"),

  });
};


getErrorMessage = (touched: any, errors: any, value: any) => {
  return this.handleCondition(this.handleCondition(touched[value], errors[value], ""),
    <Typography style={{ color: 'var(--Status-Red-600, #DC2626)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: 'DIN Next LT Arabic Regular',
        lineHeight: '18px', 
        textAlign: this.handleCondition(this.props.i18n?.language === 'ar', 'right', 'left')
      }}
    >
      {errors[value]}
    </Typography>,
    ""
  );
};
getServerErrorMessage = (errorMessage:any) => {
  return errorMessage ? (<Typography
      style={{ 
        lineHeight: '18px', 
        color: 'var(--Status-Red-600, #DC2626)',
        fontFamily: 'DIN Next LT Arabic Regular',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', 'right', 'left')
      }}
    >
      {errorMessage}
    </Typography>
  ) : null;
    }
 TabPanel = (props: TabPanelProps) => {const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>

         
        )}
      </div>
    );
  }

  a11yProps = (index: any) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  renderPasswordEye = (conditionValidation:any)=>{return(
    conditionValidation ? (
      <VisibilityOff />
    ) : (
      <Visibility />
    )
  )}
  getTabClassFirst(state:number) {
   if(state==0){
    return 'tabSelected';
   }
  }
  getTabClassSecond(state:number) {
    if(state==1){
     return 'tabSelected';
    }
   }
   getTabClassThird(state:number) {
    if(state==2){
     return 'tabSelected';
    }
   }
tabsVariantBreakpoint(){
  if(theme.breakpoints.down(300)){ 
    return 'fullWidth'
  } else {
    return 'standard'
  }
} 
tabsScrollButtons(){
  if(theme.breakpoints.down(300)){ 
    return 'on'
  } else {
    return 'auto'
  }

}

renderSaveChangesButton = () => {
  if (!this.state.editPasswordFormEnable) {
    return (
      <Button
        type="submit"
        disabled={this.state.editFormEnable}
        classes={{root:`${"MuiButton-root"}${"MuiButton-label"}`}}
      >
        {this.props.i18n?.t('saveChanges')}
      </Button>
    );
  }
  return null;
};

renderImagePreview = () => {
  if (this.state.imagePreviewUrl) {
    return (
      <Box style={{}}>
        <img
          src={this.state.imagePreviewUrl}
          alt="Selected file preview"
          style={{ maxWidth: '200px', maxHeight: '200px' }}
        />
      </Box>
    );
  }
  return null;
};
renderSaveChangesBottomButton = (isValid:any,dirty:any) => {
  

  return (
    <Button
      disabled={!(isValid && dirty)}
      type="submit"
      style={{
        backgroundColor: isValid && dirty ? '#1B4FE4' : '#F1F5F9'
      }}
      classes={{ root: "MuiButton-root" }}
    >
      <Typography
        style={{
          color: isValid && dirty ? '#FFFFFF' : '#64748B',
          fontFamily: 'DIN Next LT Arabic Bold',
          fontSize: '16px',
          fontWeight: 700,
          lineHeight: '24px',
          display: 'contents',
          textTransform: 'none'
        }}
      >
        {this.props.i18n && this.props.i18n.t('saveChanges')}
      </Typography>
    </Button>
  );
};
getPasswordFieldType = (state:boolean) => {
  if(state){return "password"}else{return "text"};
};
renderCalender = () => {
  return (<CustomDatePicker>
    {this.state.calendarOpen && (
      <div className="reactCalendarBox" style={{ position: "absolute" }}>
        <div className="calBox">
          <Calendar
            minDate={new Date()}
            onChange={this.handleDateChange}
            data-testID="calendar"
            value={this.state.formattedDate}
          />
        </div>
      </div>
    )}
  </CustomDatePicker>)
}

renderIdProofExpiryDate = () => {
  return (<Box className="input_Box">
  <Typography id="Id proof exiry date" className="labeltext">
    {this.props.i18n?.t("idProofExpiryDate")}
  </Typography>
  <Input
    disabled={this.state.editFormEnable}
    data-test-id="txtInputFirstName"
    name="id_expiry_date"
    style={{
      marginTop: '10px',
      padding: '5.5px 8px',
      borderRadius: '8px',
      border: '1px solid rgb(203, 213, 225)',
      textAlign: 'start',
      fontFamily: 'DIN Next LT Arabic Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: '#334155',
      background: this.handleCondition(this.state.editFormEnable , 'rgb(238, 238, 238)' , '#fff')
    }}
    fullWidth
    disableUnderline
    endAdornment={
      <img
        src={calendar}
        onClick={this.handleCondition(!this.state.editFormEnable , this.openCalendar , undefined)}
        className="calendarIcon"
        style={{
          cursor: this.handleCondition(this.state.editFormEnable , 'default' , 'pointer'),
          opacity: this.handleCondition(this.state.editFormEnable , 0.5 , 1),
        }}
      />
    }
    value={this.formatDate(this.state.formattedDate)}
  />
  {this.renderCalender()}
</Box>)
}
 // Customizable Area End
  render() {
    // Customizable Area Start
    const { currencyList, selectedCurrency, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    // Customizable Area End
    return (
       // Customizable Area Start
       <React.Fragment>
          <React.Fragment>
             {this.state.tokenExpireStatus===false && <div style={{display:'flex'}}>
    <NavigationMenu id="" navigation={this.props.navigation} profilePicture = {this.state.profileImgUrl} userFirstName = {this.state.firstName} userLastName = {this.state.lastName} i18n={this.props.i18n}>
       </NavigationMenu>
     <MainWrapper>
      <ThemeProvider theme={theme}>
        <Box>
                  <Dialog open={this.state.open} data-test-id="handleClose" onClose={this.handleClose}>
                    <DialogContent>
                      <Box data-test-id="handleClickCloseUploadBox" onClick={this.handleClickCloseUploadBox} style={{ display: 'flex', cursor: 'pointer', justifyContent: this.handleCondition(this.props.i18n?.language === 'ar', "start", "end") }}>
                        <img src={this.state.crossIconButton} alt="" />
                      </Box>
                      <Formik
                        data-test-id="formikImage"
                        initialValues={{
                          file: '',
                          filename: ''
                        }}

                        onSubmit={(values: any, { resetForm }) => {
                          this.updateUserProfileImg(values);
                          resetForm();
                        }}
                      >
                        {({
                          handleSubmit,
                          setFieldValue,
                          setErrors,
                          setTouched,
                          setFieldError,
                          errors,
                        }) => {
                          return (
                          <form
                            onSubmit={handleSubmit}
                            noValidate
                            autoComplete="off"
                          >
                            <Box
                              role="button"
                              onClick={this.handleClick}
                              style={{
                                display: 'flex', flexDirection: 'column', gap: '12px',
                                height: '267px',
                                marginRight: '30px',
                                marginLeft: '30px'
                              }}
                            >

                              <Box style={{
                                height: '300px',
                                border: '1px solid grey',
                                borderStyle: 'dashed',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '16px',
                                borderColor: '#B0BACD',
                                marginTop: '24px'
                              }}>
                                {!this.state.imagePreviewUrl && (<Box style={{}}>
                                  <Box style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                    <input
                                      hidden
                                      type="file"
                                      data-test-id="files"
                                      name="file"
                                      accept=".jpg, .jpeg, .png"
                                      className="fileStyle"
                                      ref={this.state.inputRef}
                                      onChange={(e) => this.handleProfileImageUpload(e, setFieldError, setFieldValue)}
                                    />
                                    <img src={this.state.uploadIconSmall} alt="" />
                                  </Box>
                                  <Box>
                                    <Typography style={{
                                      color: '#1E293B',
                                      fontFamily: 'DIN Next LT Arabic Bold',
                                      fontSize: '18px',
                                      fontWeight: 700,
                                      lineHeight: '26px',
                                      textAlign: 'center',
                                    }}>
                                      {this.handleTranslationChange('uploadPhoto')}
                                    </Typography>
                                    <Typography style={{
                                      color: '#64748B',
                                      fontFamily: 'DIN Next LT Arabic Regular',
                                      fontSize: '14px',
                                      fontWeight: 400,
                                      lineHeight: '22px',
                                      textAlign: 'center',
                                    }}>
                                      image, .jpg, .jpeg, .png
                                    </Typography>

                                  </Box>
                                </Box>)}
                                {this.renderImagePreview()}
                              </Box>
                              {errors.file && (
                                <Typography
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    textAlign: this.props.i18n.language === "en" ? 'left' : "right"
                                  }}
                                >
                                  {errors.file}
                                </Typography>
                              )}

                            </Box>
                            <Box style={{ width: '545px', marginTop: '24px', display: "flex", justifyContent: this.handleCondition(this.props.i18n?.language === 'ar', "flex-end", "flex-start") }}>
                              <Box style={{ paddingRight: this.handleCondition(this.props.i18n?.language === 'ar', 20, "0px") }}>
                                <Button
                                  data-test-id="img_test"
                                  disabled={this.state.imagePreviewUrl === null}
                                  style={{
                                    backgroundColor: this.state.imagePreviewUrl === null ? '#F1F5F9' : '#1B4FE4',
                                    borderRadius: '8px',
                                    padding: '10px 16px',
                                    marginLeft: '30px'

                                  }}
                                  type="submit"


                                >
                                  <Typography style={{
                                    textTransform: 'none',
                                    color: this.state.imagePreviewUrl === null ? '#94A3B8' : '#FFFFFF',
                                    fontFamily: 'DIN Next LT Arabic Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                  }}>
                                    {this.handleTranslationChange('done')}
                                  </Typography>
                                </Button>
                                <Button
                                  data-test-id="retryButton"
                                  onClick={this.handleRetry}
                                  color="secondary">
                                  <Typography style={{
                                    textTransform: 'none',
                                    color: '#1B4FE4',
                                    fontFamily: 'DIN Next LT Arabic Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    textAlign: 'left',
                                  }}>{this.handleTranslationChange('reTry')}</Typography>
                                </Button>
                              </Box>
                            </Box>
                          </form>
                        )
                        }}
                      </Formik>
                    </DialogContent>

                  </Dialog>
        <Box style={{ direction: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}}>
                    <CurrencyPopup
                      open={open}
                      anchorEl={anchorEl}
                      currencyList={currencyList}
                      selectedCurrency={selectedCurrency}
                      onClose={this.handlePopoverClose}
                      onSelectCurrency={this.handleCurrencySelection}
                    />
      {this.state.popupForLogout && (
            <Box className="logoutContainer">
              <Box className="logoutBox">
                <Box className="textBox">
                  <Box style={{display: "flex", justifyContent:"space-between", alignItems:"center"}}>
                  <Typography className="surefor">
                  {this.handleTranslationChange('sureForLogout')}
                  </Typography>
                    <img onClick={this.handleLogoutPopup} src={crossIconButton} className="crossStyle"/>
                  </Box>
                  <Typography className="backfor">
                  {this.handleTranslationChange('sureForGoBack')}
                  </Typography>
                </Box>
                <Box className="buttonBox">
                  <button
                  className="cancel"
                    onClick={this.handleLogoutPopup}
                  >
                    {this.handleTranslationChange('cancel')}
                  </button>
                  <Box className="back"
                  onClick={this.handleUserLogout}
                  >
                    <img src={logg} />
                    <Typography
                    className="logg"
                    >
                      {this.handleTranslationChange('logout')}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {this.state.deactivatePopup && (
            <Box className="logoutContainer">
            <Box className="logoutBoxDeactive">
              <Box className="textBox">
                <Box style={{display: "flex", justifyContent:"space-between", alignItems:"center"}}>
                <Typography className="surefor">
                {this.handleTranslationChange('sureForDeactive')}
                </Typography>
                  <img onClick={this.handleOpenPopup} src={crossIconButton} className="crossStyle"/>
                </Box>
                <Typography className="backfor">
                {this.handleTranslationChange('sureForGoBack')}
                </Typography>
              </Box>
              <Box className="buttonBox">
                <button
                className="cancel"
                  onClick={this.handleOpenPopup}
                >
                  {this.handleTranslationChange('cancel')}
                </button>
                <Box className="back2"
                 onClick={this.handleDeactivateAccountApi}
                >
                  <Typography
                  className="deactive"
                  >
                   {this.handleTranslationChange('deactivateAccount')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          )}
         <Paper elevation={3} className="paper">
          <Box className="first_main_box">
         
           
            <Box className="first_main_box_right">
                <Badge
                overlap="circular"
                anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',   
                
               }}
               
        badgeContent={
          <IconButton data-test-id="handleClickOpenUploadBox" onClick={this.handleClickOpenUploadBox}>  
        <SmallAvatar alt="Remy Sharp" src={this.state.editButtonIcon}/>
        </IconButton>
      }
      >          
      
                <Avatar alt="Remy Sharp" src={this.state.profileImgUrl} className="avatarImg"/>
               </Badge>
                </Box>
                
           
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
          {  <Box className="first_main_box_left" style={{paddingRight: this.handleCondition(this.props.i18n?.language === 'ar', "35px", "0px")}}>
                    <Typography className="first_main_box_typo_1" style={{textAlign: this.handleCondition(this.props.i18n.language === 'ar', "right", "left")}}>
                    {this.state.firstName} {this.state.lastName}
                    </Typography>
                    <Typography className="first_main_box_typo_2" style={{textAlign: this.handleCondition(this.props.i18n.language === 'ar', "right", "left")}}>
                        {this.state.email}
                    </Typography>
                    <Typography className="first_main_box_typo_3" style={{textAlign: this.handleCondition(this.props.i18n.language === 'ar', "right", "left"), direction: this.handleCondition(this.props.i18n.language === 'ar', 'ltr', 'ltr')}}>
                        <span className="first_main_box_typo_4">+{this.state.currentCountryCode}</span>- {this.state.phoneNumber}
                    </Typography>
                  
                </Box>}
                
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
             <Box sx={{height:'100%',width:'100%',display:'flex',justifyContent:{xs:'start',sm:'end',md:'end'},paddingLeft:{xs:'32px',sm:'0px',md:'0px'}
            ,[theme.breakpoints.down('xs')]:{
              marginLeft:'-4px',
              marginTop:'-20px'
            },
            [theme.breakpoints.down(450)]:{
              padding: '16px',
              marginLeft:'-5px',
              marginTop:'-36px'
            }
            }}>
            <Box className="first_main_box_verify">
                <Box style={{alignSelf: 'center'}}>
                    <img src={this.state.verifyIcon} alt=""/>
                </Box>
                 <Box>
                    <Typography className="first_main_box_verify_style">
                    {this.handleTranslationChange('verifiedAccount')}
                    </Typography>
                </Box>
                    
            </Box>
            </Box>
           </Grid>
           </Grid>
           </Box>
          </Paper>
         
          <Box className="tabContainer">
            <Card className="appBar" style={{marginRight: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', "36px", "0")}}>
            <Tabs
            data-testid = "change_tab"
              className="tabs"
              value={this.state.tabValue} 
              onChange={this.handleChange} 
              aria-label="simple tabs example"
              classes={{ indicator: "indicator" }}
              variant={this.tabsVariantBreakpoint()}
              scrollButtons={this.tabsScrollButtons()}
            >
              <Tab
                data-test-id="getTabClassFirst"
                label={this.handleTranslationChange('myProfile')}
                {...this.a11yProps(0)}
                classes={{ 
                  root: `${'tabRoot'} ${this.getTabClassFirst(this.state.tabValue)}`, 
                  wrapper: 'tabWrapper' 
                }}
                
              />
              <Tab
                data-test-id="getTabClassSecond"
                label={this.handleTranslationChange('accountSetting')}
                {...this.a11yProps(1)}
                classes={{ 
                   root: `${'tabRoot'} ${this.getTabClassSecond(this.state.tabValue)}`, 
                  wrapper: 'tabWrapper' 
                }}
              />
              <Tab
                data-test-id="getTabClassThird"
                label={this.handleTranslationChange('language')}
                {...this.a11yProps(2)}
                classes={{ 
                  root: `${'tabRoot'} ${this.getTabClassThird(this.state.tabValue)}`, 
                  wrapper: 'tabWrapper' 
                }}
              />
            </Tabs>
            </Card>
            </Box>
   
          <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
          {this.state.tabValue === 0 && (
          <Paper elevation={3} className="paper_tab">
            
            <this.TabPanel value={this.state.tabValue} index={0}>
            <Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className="editForm_Title_Typo">{this.handleTranslationChange('personalDetails')}</Typography>
              </Grid>
              <Grid item>
              <Box className="editForm_Edit_Button_Box">
                        <Box className="editForm_Edit_Icon_Box">
                            <img src={this.state.editIcon} alt=""/>
                        </Box>
                        <Box className="editForm_Edit_Typo_Box">
                          <Typography data-test-id="enableDisableEdit" onClick={()=>{this.enableDisableEdit()}} className="editForm_Edit_Button_Typo">
                          {this.handleTranslationChange('edit')}
                          </Typography>
                        </Box>
                    </Box>
              </Grid>
            </Grid>
            <Box mt={3}>
            <Formik
                    data-test-id="formikProfile"
                    enableReinitialize
                    initialValues={{
                      firstName:this.state.firstName,
                      lastName: this.state.lastName,
                      email: this.state.email,
                      countryName: this.state.countryName.toLowerCase(),
                      currentCountryCode : '+'+this.state.currentCountryCode,
                      phoneNumber: this.state.phoneNumber,
                      investmentService: this.state.investmentService,
                      idProof: this.state.idProof,
                      filename: this.state.documentImgFileName,
                      file: this.state.documentImgUrl,
                      
                     
                    }}
                    validationSchema={ this.handleCondition(this.props.i18n?.language === "ar" , this.userSchemaAr, this.userSchema)}
                    onSubmit={(values: any) => {
                      this.updateUserProfile(values) 
                    }}
                  >
                    {({
                      handleSubmit,
                      errors,
                      values,
                      handleBlur,
                      touched,
                      setFieldValue,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
              <Grid container spacing={2}>
                
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="input_Box">
                    <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', "right", "left")}}>
                          {this.handleTranslationChange('firstName')}
                        </Typography>

                        <TextField
                          disabled={this.state.editFormEnable}
                          data-test-id="txtInputFirstName"
                          variant="outlined"
                          className="textFieldRoot"
                          name="firstName"
                          placeholder={this.handleTranslationChange('enterFirstName')}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.firstName}
                          onChange={(e) => {
                            setFieldValue("firstName", e.target.value);
                           
                          }}
                          error={!!this.handleCondition(touched.firstName , errors.firstName, "")}
                          InputProps={{classes: { root: "textFieldRoot"}}}
                        />
                         {this.getErrorMessage(touched, errors, "firstName")}
                    </Box>
                  </Grid>
                  <Grid 
                  item 
                  xs={12} sm={6} md={6} lg={6}>
                  <Box className="input_Box">
                  <Typography className="input_typo_style"
                   style={{textAlign: this.handleCondition(this.props.i18n && this.props.i18n.language === 'ar', "right", "left")}}>
                    {this.handleTranslationChange('lastName')}
                        </Typography>

                        <TextField
                          disabled={this.state.editFormEnable}
                          data-test-id="txtInputLastName"
                          className="textFieldRoot"
                          variant="outlined"
                          name="lastName"
                          placeholder={this.handleTranslationChange('enterLastName')}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.lastName}
                          onChange={(e) => {
                            setFieldValue("lastName", e.target.value);
                          }}
                          error={!!this.handleCondition(errors.lastName, touched.lastName,"")}  InputProps={{classes: {root: "textFieldRoot",}, }}
                        />
                         {this.getErrorMessage(touched, errors, "lastName")}
                  </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="input_Box"><Typography className="input_typo_style" 
                    style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                    {this.handleTranslationChange('email')} </Typography>
                        <TextField
                          disabled={this.state.editFormEnable}
                          data-test-id="txtInputEmail"
                          variant="outlined"
                          className="textFieldRoot"
                          name="email"
                          placeholder={this.handleTranslationChange('enterEmail')}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.email}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                          }}   error={!!this.handleCondition(errors.email, touched.email, "")}   InputProps={{ classes: { root: "textFieldRoot",},
                          }}   />  {this.getErrorMessage(touched, errors, "email")}  </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="input_Box">
                    <Typography className="input_typo_style" 
                    style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}
                    > {this.handleTranslationChange('country')}
                      </Typography>
                      <Select  
                          disabled={this.state.editFormEnable}
                          placeholder={this.handleTranslationChange('selectCountry')}
                          variant="outlined"
                          id="countryName"
                          data-test-id="txtInputCountryName"
                          name="countryName"
                          value={values.countryName}
                          fullWidth
                          onBlur={handleBlur}
                          error={!!this.handleCondition(errors.countryName, touched.countryName,"")}
                          onChange={(e) =>
                            setFieldValue("countryName", e.target.value)
                          }
                          className={`selectRoot ${this.handleCondition(this.props.i18n?.language === "ar" , "arabic" , "english")}`}

                        >
                          <MenuItem value="none" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>Select your country</MenuItem>
                          {configJSON.countryList.map(
                            (obj: any, index: any) => (
                              <MenuItem key={index} value={obj.value} style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                                {obj.label}
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {this.getErrorMessage(touched, errors, "countryName")}
                    </Box>
                  </Grid>
                  <Grid 
                  item  
                  xs={12} 
                  sm={6} 
                  md={6}    
                  lg={6}>
                  <Box className="input_Box">
                  <Typography className="input_typo_style" 
                  style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>   {this.handleTranslationChange('phoneNo')}
                        </Typography>
                        <TextField
                        disabled={this.state.editFormEnable}
                          data-test-id="txtInputPhoneNumber"
                          variant="outlined"
                          name="phoneNumber"
                          placeholder={this.handleTranslationChange('enterPhoneNo.')}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                          error={!!this.handleCondition(errors.phoneNumber, touched.phoneNumber, "")}
                          className={`textPhoneNumberFieldRoot ${this.handleCondition(this.props.i18n?.language === "ar" , "DirectionAr" , "english")}`}
                          onChange={(e) => {
                            const numericValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            setFieldValue("phoneNumber", numericValue);
                            
                          }}
                        
                          InputProps={{
                            classes: {
                              root: "textPhoneNumberFieldRoot",
                            },
                             startAdornment: (
                <InputAdornment position="start">
                  <Select
                  className={`textPhoneNumberFieldRoot ${this.handleCondition(this.props.i18n?.language === "ar" , "selectAr" , "english")}`}
                  disabled={this.state.editFormEnable}
                    value={values.currentCountryCode}
                    onChange={(e) =>
                      setFieldValue("currentCountryCode", e.target.value)
                    }
                    disableUnderline
                    data-test-id="txtInputCurrentCountryCode"
                   
                  >
                    {configJSON.countryCodeList.map((obj:any, index:any) => (
                      <MenuItem key={index} value={obj.value}>
                        {obj.label}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
              inputProps: {
                maxLength: 11,
                minLength: 7, 
              },
                          }}
                        />
                         {this.getErrorMessage(touched, errors, "phoneNumber")}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="input_Box">
                        

                    <Typography className="input_typo_style" 
                    style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar',
                     "right",
                      "left")}}>
                            {this.handleTranslationChange('investService')}
                          </Typography>
                        
                        <Select
                          disabled={this.state.editFormEnable}
                          variant="outlined"
                          name="investmentService"
                          fullWidth
                          data-test-id="txtInputInvestmentService"
                          value={values.investmentService}
                          onBlur={handleBlur}
                          className={`selectRoot ${this.handleCondition(this.props.i18n?.language === "ar" , "arabic" , "english")}`}
                          onChange={(e) =>
                            setFieldValue("investmentService", e.target.value)
                          }
                          error={!!this.handleCondition(errors.investmentService, touched.investmentService, "")}
                        >
                          <MenuItem value="none" disabled style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>{this.handleTranslationChange('selectYourService')}</MenuItem>
                          {this.handleCondition(this.props.i18n?.language === 'ar', configJSON.investmentAr , configJSON.investment).map((obj:any, index:any) => (
                            <MenuItem key={index} value={obj.value} style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                              {obj.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.getErrorMessage(touched, errors, "investmentService")}
                    </Box>
                  </Grid>
                  
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                
                     <Box className="input_Box">
                     <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                          {this.handleTranslationChange('id')}
                          </Typography>
                       {this.handleCondition(this.state.cancelDocBox , (
                       <>
                       <Select
                          disabled={this.state.editFormEnable}
                          variant="outlined"
                          id="id"
                          name="idProof"
                          data-test-id="txtInputIdProof"
                          fullWidth
                          value={values.idProof}
                          onBlur={handleBlur}
                          onChange={(e) => setFieldValue("idProof", e.target.value)}
                          className={`selectRoot ${this.handleCondition(this.props.i18n?.language === "ar" , "arabic" , "english")}`}
                        >
                          <MenuItem value="none" disabled style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>{this.handleTranslationChange('selectID')}</MenuItem>
                          {this.handleCondition(this.props.i18n?.language === 'ar', configJSON.idProofAr , configJSON.idProof).map((obj:any, index:any) => (
                            <MenuItem key={index} value={obj.value} style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
                              {obj.label}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.getErrorMessage(touched, errors, "idProof")}
                      </>

                      ),"")}
                   
                        <Box
                          role="button"
                          data-test-id="handleClick"
                          onClick={this.handleClick}
                          className="imgCon"
                          
                        >
                          {this.handleCondition(
                            values.filename === "",
                            <Box className="imgBox">
                              <input
                                disabled={this.state.editFormEnable}
                                type="file"
                                data-test-id="files"
                                name="file"
                                accept=".pdf, image/*"
                                className="fileStyle"
                                multiple
                                ref={this.state.inputRef}
                                onChange={(
                                  event
                                ) => {
                                  const selectedFile = event.target.files?.[0];
                                  if (selectedFile) {
                                    const fileSize = selectedFile.size;
                                    const maxSizeInBytes = 2 * 1024 * 1024;
                                    const fileExtension = selectedFile.name?.split('.').pop()?.toLowerCase();
                                    if (fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === "pdf") {
                                      setFieldValue("file", selectedFile);
                                      if (fileSize < maxSizeInBytes) {
                                        setFieldValue("filename", selectedFile.name);
                                        this.setCustomError("");
                                      } else {
                                        this.setCustomError(this.handleTranslationChange('exceedFormat'));
                                      }
                                    } else {
                                      this.setCustomError(this.handleTranslationChange('onlyJpgFormat'));
                                    }
                                  }
                                }}
                              />
                              <img
                                src={uploadIcon}
                                className="fileupLoad"
                              />
                              <Typography className="uploadText1">
                                {this.handleTranslationChange('uploadIdproof')}
                              </Typography>
                              <Typography className="uploadText2">
                                image, pdf, png
                              </Typography>
                            </Box>,
                            <Typography className="imgBox2">
                              <Box className="pdfBox">
                                
                                {this.handleCondition(
                                  values.filename?.includes(".pdf"),
                                  <img
                                    src={pdfImage}
                                    className="pdfStyle"
                                  />,
                                  <img
                                    src={pngImage}
                                    className="pdfStyle"
                                  />
                                )}
                                <Typography>{values.filename || ""}</Typography>
                              </Box>
                              <img
                              data-test-id = "image_test"
                                style={{ cursor: this.handleCondition(this.state.editFormEnable , 'default' , 'pointer'), pointerEvents: this.handleCondition(this.state.editFormEnable , 'none' , 'auto') }}
                                src={crossIcon}
                                onClick={() => {
                                 
                                  this.setState({cancelDocBox:true})
                                  setFieldValue("filename", "");
                                  setFieldValue("file", "");
                                }}
                              />
                            </Typography>
                          )}
                        </Box>
                        {this.handleCondition(this.state.customError !== "" , (
                          <Typography style={{ marginTop: "2px",
                          fontSize: "14px",
                          color: "#f94b4b",
                          display:"flex", justifyContent:"flex-start"}}>
                            {this.state.customError}
                          </Typography>
                        ) , "")}
                        {this.getErrorMessage(touched, errors, "file")}
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                        {this.renderIdProofExpiryDate()}
                  </Grid>
                 
                  {this.renderSaveChangesButton()}
                </Grid>
                </form>
                    )}
            </Formik>  
            </Box>
            <Grid container justifyContent="space-between" alignItems="center" className="passwordGrid">
              <Grid item>
                <Typography className="editForm_Title_Typo">
                {this.handleTranslationChange('security/password')}
                  </Typography>
              </Grid>
              <Grid item>
              <Box className="editForm_Edit_Button_Box">
                        <Box className="editForm_Edit_Icon_Box">
                            <img src={this.state.editIcon} alt=""/>
                        </Box>
                        <Box className="editForm_Edit_Typo_Box">
                          <Typography data-test-id = "enable_disable_test" onClick={()=>{this.enableDisableEditPassowrd()}} className="editForm_Edit_Button_Typo">
                            {this.handleTranslationChange('edit')}
                          </Typography>
                        </Box>
                    </Box>
              </Grid>
                </Grid>
            <Box mt={3}>
            
            <Formik
                    data-test-id="formikPassword"
                    initialValues={{
                      passwordText:'',
                      currentPasswordText:this.state.currentPasswordText,
                      newPasswordText: this.state.newPasswordText,
                      reTypePasswordText:this.state.reTypePasswordText,
                    }}
                    validationSchema={this.handleCondition(this.props.i18n?.language === 'ar', this.userPasswordSchemaAr , this.userPasswordSchema)}
                    onSubmit={(values: any) => {
                  
                      this.validateAndUpdateProfilePassword(values);
                    }}
                    innerRef={this.state.formikRef}
                  >
                    {({
                      handleSubmit,
                      errors,
                      values,
                      isValid,
                      handleBlur,
                      dirty,
                      touched,
                      setFieldValue,
                      setErrors,
                      setTouched,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        noValidate
                        autoComplete="off"
                      >
              <Grid container spacing={2}>
             
            {!this.state.editPasswordFormEnable?
            <><Grid item xs={12} sm={6} md={6} lg={6} style={{marginTop:'10px'}}>
            <Box className="input_Box">
            <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                         {this.handleTranslationChange('password')}
                        </Typography>

                        <TextField
                          disabled
                          variant="outlined"
                          className="textFieldRoot"
                         
                          name="passwordText"
                          placeholder={"**********"}
                          fullWidth
                          onBlur={handleBlur}
                          value={values.passwordText}
                        
                          error={!!this.handleCondition(errors.passwordText, touched.passwordText, "")}
                          InputProps={{
                            classes: {
                              root: "textFieldRoot",
                            },
                          }}
                        />
                    </Box>
                 </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}></Grid></>:""}
                {this.state.editPasswordFormEnable?
                  <><Grid item xs={12} sm={6} md={6} lg={6}>
                              <Box className="input_Box">
                              <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                                  {this.handleTranslationChange('currentPassword')}
                                </Typography>

                                <TextField
                                  data-test-id="txtInputCurrentPassowrd"
                                  style={{
                                    marginTop:"10px",
                                  padding:"6px 8px",width:"98.5%",
                                  borderRadius:'8px',
                                  alignItems:"center",
                                  border: this.handleCondition(
                                    this.getServerErrorMessage(this.state.currentPasswordError) || 
                                    this.getErrorMessage(touched, errors, 'currentPasswordText'),
                                   '1px solid #F87171' , '1px solid #CBD5E1')}}
                                  type={this.getPasswordFieldType(this.state.enableCurrentPasswordFieldEye)}
                                  name="currentPasswordText"
                                  placeholder={this.handleTranslationChange('enterCurrrentPassword')}
                                  fullWidth
                                  onBlur={handleBlur}
                                  value={values.currentPasswordText}
                                  onChange={(e) => {
                                    setFieldValue("currentPasswordText", e.target.value);
                                    this.setState({ currentPasswordError: ""})
                                  } }
                                  InputProps={{
                                    classes: {
                                      root: "textPasswordFieldRoot",
                                    },
                                    disableUnderline:true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton 
                                          data-test-id="handleClickShowCurrentPassword"
                                          aria-label="toggle password visibility"
                                          onClick={this.handleClickShowCurrentPasswordEye}
                                          edge="end"
                                          style={{ padding: '6px', margin: '-6px' }}
                                        >
                                          {this.renderPasswordEye(this.state.enableCurrentPasswordFieldEye)}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }} />
                                  {this.getErrorMessage(touched, errors, "currentPasswordText") || this.getServerErrorMessage(this.state.currentPasswordError)}
                              </Box>
                            </Grid><Grid item xs={12} sm={6} md={6} lg={6}></Grid><Grid item xs={12} sm={6} md={6} lg={6}>
                                <Box className="input_Box">
                                <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                                  {this.handleTranslationChange('newPassword')}
                                  </Typography>

                                  <TextField
                                    data-test-id="txtInputNewPassword"
                                    name="newPasswordText"
                                    type={this.getPasswordFieldType(this.state.enableNewPasswordFieldEye)}
                                    placeholder={this.handleTranslationChange('yourNewPassword')}
                                    fullWidth
                                    onBlur={handleBlur}
                                    value={values.newPasswordText}
                                    onChange={(e) => {
                                      setFieldValue("newPasswordText", e.target.value);
                                      this.setState({ newPasswordError: "", passwordError: "", confirmPasswordError: ""})
                                    } }
                                    style={{marginTop:"10px",
                                  padding:"6px 8px",
                                  borderRadius:'8px',alignItems:"center",
                                  width:"98.5%",
                                  border: this.handleCondition(
                                    this.getServerErrorMessage(this.state.newPasswordError) 
                                    || this.getServerErrorMessage(this.state.passwordError) 
                                    || this.getErrorMessage(touched, errors, 'newPasswordText'),
                                   '1px solid #F87171' ,    '1px solid #CBD5E1')}}
                                    InputProps={{
                                      classes: {
                                        root: "textPasswordFieldRoot",
                                      },
                                      disableUnderline:true,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            data-test-id="newPassword"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowNewPasswordEye}
                                            edge="end"
                                            style={{ padding: '6px', margin: '-6px' }}
                                          >
                                            {this.renderPasswordEye(this.state.enableNewPasswordFieldEye)}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }} />
                                    {this.getErrorMessage(touched, errors, "newPasswordText") 
                                    || this.getServerErrorMessage(this.state.newPasswordError) || this.getServerErrorMessage(this.state.passwordError)}
                                </Box>
                              </Grid><Grid item xs={12} sm={6} md={6} lg={6}></Grid><Grid item xs={12} sm={6} md={6} lg={6}>
                                <Box className="input_Box">
                                <Typography className="input_typo_style" style={{textAlign: this.handleCondition(this.props.i18n?.language === 'ar', "right", "left")}}>
                                  {this.handleTranslationChange('reEnterPassword')}
                                  </Typography>

                                  <TextField
                                    data-test-id="txtInputConfirmPassword"
                                    name="reTypePasswordText"
                                    type={this.getPasswordFieldType(this.state.enableConfirmPasswordFieldEye)}
                                    placeholder={this.handleTranslationChange('reEnterNewPassword')}
                                    fullWidth
                                    style={{marginTop:"10px",
                                    width:"98.5%",
                                    padding:"6px 8px",borderRadius:'8px',
                                    alignItems:"center",
                                    border: this.handleCondition(
                                      this.getServerErrorMessage(this.state.confirmPasswordError) ||
                                      this.getErrorMessage(touched, errors, 'reTypePasswordText'),
                                     '1px solid #F87171' , 
                                     '1px solid #CBD5E1')}}
                                    onBlur={handleBlur}
                                    value={values.reTypePasswordText}
                                    onChange={(e) => {
                                      setFieldValue("reTypePasswordText", e.target.value);
                                      this.setState({ confirmPasswordError: "", newPasswordError: ""})                                    } }
                                    InputProps={{
                                      disableUnderline:true,
                                      classes: {
                                        root: "textPasswordFieldRoot",
                                      },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            data-test-id="handleClickShowRePassword"
                                            aria-label="toggle password visibility"
                                            onClick={this.handleClickShowConfirmPasswordEye}
                                            edge="end"
                                            style={{ padding: '6px', margin: '-6px' }}
                                          >
                                            {this.renderPasswordEye(this.state.enableConfirmPasswordFieldEye)}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }} />
                                     {this.getErrorMessage(touched, errors, "reTypePasswordText") ||
                                      this.getServerErrorMessage(this.state.confirmPasswordError)}
                                </Box>
                              </Grid><Grid item xs={12} sm={6} md={6} lg={6}></Grid>
                             
                                       {this.renderSaveChangesBottomButton(isValid,dirty)}
                                       </>
                          :''}


              </Grid> 
              </form>
                    )}
              </Formik>
              </Box>
           
              
          </Box>
          
            </this.TabPanel>
          
            
          </Paper>
        )}
          {this.state.tabValue === 1 && (
          <Box className="notify">
          <Paper className="notification_container">
            <Box className="notification_box">
              <Box className="notify_box">
                <Typography className="notify_titles">{this.handleTranslationChange('notification')}</Typography>
                <Box className="all_notify_types">
                <Typography className="notification_content">{this.handleTranslationChange('allNotification')}</Typography>
                <IOSSwitch checked={this.state.emailNotification && this.state.inappNotification} onClick={(e)=> this.enableAllNotificationApi('all', e)} data-test-id={"enableAllNotificationApi"}/>
                </Box>
                <Box className="all_notify_types">
                <Typography  className="notification_content">{this.handleTranslationChange('emailNotification')}</Typography>
                <IOSSwitch checked={this.state.emailNotification} onClick={(e) => this.enableAllNotificationApi('email', e)} data-test-id={"enableEmailNotificationApi"}/>
                </Box>
                <Box className="all_notify_types">
                <Typography  className="notification_content">{this.handleTranslationChange('inAppNotification')}</Typography>
                <IOSSwitch checked={this.state.inappNotification} onClick={(e) => this.enableAllNotificationApi('inapp', e)} data-test-id={"enableInappNotificationApi"}/>
                </Box>
                <Box className="all_notify_types">
                <Typography  className="notification_content">{this.handleTranslationChange('promotionalNotification')}</Typography>
                <IOSSwitch />
                </Box>
              </Box>
              <Box className="notify_box">
                <Typography className="notify_titles">{this.handleTranslationChange('generalSetting')}</Typography>
                <Box className="general_settings" onClick={this.handlePopoverOpen}>
                <Typography  className="notification_content">{this.handleTranslationChange('preferredCurrency')}</Typography>
                <img src={this.handleCondition(this.props.i18n?.language === 'ar' , leftA , arrowSide)}/>
                </Box>
                <Box className="general_settings" onClick={this.handlePrivacyPolicyNavigate}>
                <Typography  className="notification_content">{this.handleTranslationChange('privacyPolicy')}</Typography>
                <img src={this.handleCondition(this.props.i18n?.language === 'ar' , leftA , arrowSide)}/>
                </Box>
                <Box className="general_settings" onClick={this.handleTermsConditionNavigate}>
                <Typography  className="notification_content">{this.handleTranslationChange('terms&condition')}</Typography>
                <img src={this.handleCondition(this.props.i18n?.language === 'ar' , leftA , arrowSide)}/>
                </Box>
                <Box onClick={this.navigateToHelp} className="general_settings">
                <Typography className="notification_content">{this.handleTranslationChange('helpCenter')}</Typography>
                <img src={this.handleCondition(this.props.i18n?.language === 'ar' , leftA , arrowSide)}/>
                </Box>
                <Box className="general_settings" onClick={this.handleFeedback}>
                <Typography  className="notification_content">{this.handleTranslationChange('sendFeedback')}</Typography>
                <img src={this.handleCondition(this.props.i18n?.language === 'ar' , leftA , arrowSide)}/>
                </Box>
              </Box>
                {this.state.feedback && (
                  <Feedback id="" navigation="" feedbackNav={this.handleFeedback} i18n={this.props.i18n} reviewListNav={this.handleReviewList}></Feedback> 
                )}
              <Box className="account_buttons">
                <button className="deactive_button" onClick={this.handleOpenPopup}>{this.handleTranslationChange('deactivateAccount')}</button>
                <Typography className="forMobile" onClick={this.togglePopup}>Logout</Typography>
                <Box data-test-id="logoutButton" className="logout_box" onClick={this.handleLogoutPopup}>
                  <img src={arrow}/>
                  <Typography className="logout_button" >{this.handleTranslationChange('logout')}</Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
          </Box>
          )}

         {this.state.tabValue === 2 && (
          <Box className="language">
          <Paper className="language_container">
            <Box className="language_box">
              <Box className="language_box">
                <Box className="all_language_types">
                <Box className="language_flag">
                 <img width="24px" height="24px" src={flagSaudi} alt=""/>
                <Typography className="language_content">{this.handleTranslationChange('Arabic')}</Typography>
                </Box>
                <IOSSwitch 
                data-test-id="checkedSwitchArabic"
                checked={this.state.checkedSwitchArabic} 
                onChange={this.handleChangeLanguage} 
                />
                </Box>
                <Box className="all_language_types">
                <Box className="language_flag">
                <img src={this.state.flagUK} alt=""/>
                <Typography  className="language_content">English</Typography>
                </Box>
                <IOSSwitch 
                data-test-id="checkedSwitchEnglish"
                checked={this.state.checkedSwitchEnglish} 
                onChange={this.handleChangeLanguage} 
                />
                </Box>
              </Box>
            </Box>
          </Paper>
          </Box>
          )}
          </Box>
        </Box>
      </ThemeProvider>
      </MainWrapper>
    
      </div>}
          </React.Fragment>
      </React.Fragment>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const CustomDatePicker = styled(Box)({
  borderRadius: '8px',
  position: 'relative' as 'relative',
  boxSizing: "border-box",
  "& .MuiInput-underline::after, .MuiInput-underline::before": {
    display: 'none'
  },
  "& .reactCalendarBox":{
    [theme.breakpoints.down(400)]: {
      left:0
     },
    width: "100%",
    top: 0,
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    flexDirection:"column" as "column",
    alignItems: "flex-end",
    right: 0,
    boxShadow: "0px 2px 4px 0px #00000026",
  },
  "& .react-calendar": {
    [theme.breakpoints.down(450)]: {
      width:"275px"
    },
    width:"330px",
    border: "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  "& .react-calendar__navigation button.react-calendar__navigation__label": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#0F172A",
  },
  "& .react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button":{
    display: "none",
  },
  "& .react-calendar__navigation button": {
    fontSize: '14px',
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 700,
    color: "#94A3B8", 
  },
  "& .react-calendar__tile.react-calendar__tile--now": {
    background: 'none',
    color: '#0F172A',
  },
  "& .react-calendar__month-view__weekdays__weekday": {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#64748B',
    fontWeight: 400,
    textDecoration: 'none',
  },
  "& .react-calendar__month-view__weekdays__weekday abbr": {
    textDecoration: 'none',
  },
  "& .react-calendar__tile": {
    fontSize: '14px',
    fontWeight: 400,
    color: '#0F172A',
    width: '50px',
    height: '50px',
  },
  "& .react-calendar__tile.react-calendar__tile--range": {
    background: '#F1F5F9',
    color: '#044352',
    borderRadius: '0px',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeStart": {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
    background: '#F1F5F9', 
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--rangeEnd": {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
    background: '#F1F5F9',
    color: '#044352',
  },
  "& .react-calendar__tile.react-calendar__tile--active, .react-calendar__tile.react-calendar button:enabled": {
    background: '#1B4FE4',
    color: '#F8FAFC',
    fontSize: '14px',
    fontWeight: 700,
  },
  "& .react-calendar__tile.react-calendar__tile--active:hover, .react-calendar__tile.react-calendar button:enabled:hover": {
    background: '#F1F5F9',
    color: '#044352',
    pointerEvents: "none",
},
  "& .react-calendar__tile.react-calendar__month-view__days__day--neighboringMonth": {
    color: '#94A3B8'
  },
  "& .action-button-div": {
    [theme.breakpoints.down(450)]: {
      width:"calc(275px - 30px)"
    },
    width:"calc(330px - 30px)",
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 15px',
    gap: '20px',
    backgroundColor: "#FFFFFF"
  },
  "& .action-button-div button": {
    width: '140px',
    height: '50px',
    borderRadius: '7.21px',
    fontFamily: "DIN Next LT Arabic Regular",
    textTransform: 'capitalize',
    fontSize: '14.42px',
  },
  "& .action-button-div .cancel-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
  "& .action-button-div .save-btn.active": {
    background: '#1B4FE4',
    color: '#FFFFFF'
  },
  "& .action-button-div .save-btn": {
    background: '#F1F5F9',
    color: '#94A3B8',
    boxShadow: "0px 3.6px 3.6px 0px #00000040"
  },
});
export const UserProfileBasicBlockWebHoc = withTranslation()(UserProfileBasicBlockWeb)
// Customizable Area End
