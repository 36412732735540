// Customizable Area Start
import React from "react";
import {
    Box, Typography, Avatar, Card, CardContent,
    Container, createTheme
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import {
    styled
} from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { formatDistanceToNow } from "../../../components/src/utils.web"
import { imgArrow, imgArrowSmall } from "../../../blocks/customform/src/assets";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme({
    palette: {
      primary: {
        main: "#0000ff",
        contrastText: "#fff",
      },
    },
  });

import LandingPageControllerWeb, {
    Props,
} from "./LandingPageController";
// Customizable Area End

export default class CustomerReviews extends LandingPageControllerWeb {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    MainWrapper = styled(Box)(({ theme }) => ({
        width: "100%",
        maxWidth: "100%",
        margin: "0 auto",
        padding: theme.spacing(4, 2),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(4),
        },
        "& .container": {
            maxWidth: "1002px",
            margin: "0 auto",
        },
        "& .headingFlexBox": {
            display: "flex",
            justifyContent: "space-between",
            marginBottom: theme.spacing(4),
        },
        "& .headingText": {
            color: "#1E293B",
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: "30px",
            fontWeight: 700,
            lineHeight: "40px",
            letterSpacing: "-0.15px",
            [theme.breakpoints.down("sm")]: {
                fontSize: "24px",
                lineHeight: "32px",
            },
        },
        "& .reviewList": {
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(3),
        },
        "& .reviewCard": {
            borderRadius: theme.shape.borderRadius,
            boxShadow: theme.shadows[0],
        },
        "& .reviewContent": {
            padding: theme.spacing(3),
        },
        "& .reviewHeader": {
            display: "flex",
            alignItems: "flex-start",
            gap: theme.spacing(2),
        },
        "& .avatar": {
            width: 44,
            height: 44,
        },
        "& .reviewInfo": {
            flex: 1,
        },
        "& .reviewInfoHeader": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: theme.spacing(1),
        },
        "& .userName": {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#0F172A",
        },
        "& .reviewDate": {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            color: "#97999A",
        },
        "& .reviewDescription": {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#64748B",
        },
        "& .paginationRoot": {
            '& .MuiPaginationItem-root': {
                borderRadius: 4,
                marginRight: '16px'
            },
            '& .MuiPaginationItem-page:not(.Mui-selected)': {
                border: '1px solid #94A3B8',
                color: '#94A3B8',
            },
        },
        "& .paginationCls": {
            [theme.breakpoints.down('xs')]: {
                paddingRight: '20px'
            }
        },
        "& .arrowButton": {
            width:'32px',
            height:'32px',
            cursor: 'pointer',
            display: "flex",
            '&:hover': {
              transition: "0.7s"
            },
            [theme.breakpoints.down(480)]: {
                marginLeft:"0",
                marginTop: '4vh'
            },
        },
        "& .arrowAndHeadingBox": {
            marginBottom: '1rem'
        }
    }));

    renderPagination() {
        const { paginationData } = this.state;

        if (paginationData.total_pages <= 1) return null;

        return (
            <Box marginTop={2} display="flex"
                justifyContent={this.props.i18n.language === "ar" ? "flex-start" : "flex-end"}
                className="paginationCls">
                <Pagination
                    data-test-id="pagination"
                    count={paginationData.total_pages}
                    page={paginationData.current_page}
                    onChange={(event, page) => this.handlePaginatioChange(event, page)}
                    color="primary"
                    size="small"
                    siblingCount={0}
                    boundaryCount={1}
                    className="paginationRoot"
                />
            </Box>
        );
    }

    zeroCheck = () => {
        return (
          <Typography style={{
            color: "#64748B",
            fontSize: "16px",
            fontFamily: "DIN Next LT Arabic Regular",
            textAlign: "center",
            padding: "16px",
          }}>
            No data found
          </Typography>
        );
    };




    // Customizable Area End
    render() {
        // Customizable Area Start
        const { customerReviews, loader } = this.state;

        return (
            <this.MainWrapper>
                <Container maxWidth="md" className="container">
                    <Loader loading={loader} />
                    <Box
                    style={{
                        display: loader ? "none" : "block", // Hide content when loader is active
                    }}
                    >
                    <Box className="arrowAndHeadingBox"
                        style={{ direction: this.handleCondition(this.props.i18n.language === 'ar', 'rtl', 'ltr') }}
                    >
                        <Box data-test-id="navigateToSignUpPage_term" className="arrowButton" onClick={this.navigateToLandingPage} sx={{
                            [theme.breakpoints.up("xs")]: {
                                backgroundImage: `url(${imgArrow})`,
                                backgroundRepeat: 'no-repeat',
                                transform: this.handleCondition(this.props.i18n.language === 'ar', "rotateY(180deg)", '')
                            },
                            [theme.breakpoints.down(985)]: {
                                backgroundImage: `url(${imgArrowSmall})`,
                                backgroundRepeat: 'no-repeat'
                            }
                        }}
                        >
                        </Box>
                    </Box>
                    <Box className="headingFlexBox"
                        style={{
                            direction: this.handleCondition(
                            this.props.i18n?.language === "ar",
                            "rtl",
                            "ltr"
                            ),
                        }}
                    >
                        <Typography variant="h1" className="headingText">{this.props.i18n?.t("customerReviews")}</Typography>
                    </Box>

                    <Box className="reviewList">
                        {customerReviews.length === 0 && this.zeroCheck()}
                        {customerReviews.map((review, index) => (
                            <Card key={index} className="reviewCard">
                                <CardContent className="reviewContent" style={{
                                    direction: this.handleCondition(
                                        this.props.i18n?.language === "ar",
                                        "rtl",
                                        "ltr"
                                    ),
                                }}>
                                    <Box className="reviewHeader">
                                        <Avatar className="avatar" alt={review.userName.trim().charAt(0)} src={review.image} />
                                        <Box className="reviewInfo">
                                            <Box className="reviewInfoHeader">
                                                <Typography className="userName">{review.userName}</Typography>
                                                <Typography className="reviewDate">
                                                    {formatDistanceToNow(new Date(review.dateOfReview))}
                                                </Typography>
                                            </Box>
                                            <Typography className="reviewDescription">{review.reviewDescription}</Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                    {this.renderPagination()}
                    </Box>
                </Container>
            </this.MainWrapper>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export const CustomerReviewsWeb = withTranslation()(CustomerReviews)
// Customizable Area End
