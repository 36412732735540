// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import { toast } from "react-toastify";
interface PaginationData {
  current_page: number;
  next_page: number;
  total_pages: number;
  total_count: number;
}

interface Review {
  userName: string;
  image: string;
  dateOfReview: string;
  reviewDescription: string;
}

// Customizable Area End

// Customizable Area Start
export const configJSON = require("./config");
export interface IPaginationData {
  currentPage: number;
  nextPage: number;
  prevPage: number;
  totalPages: number;
  currentCount: number;
  totalCount: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  feedbackNav?: any;
  i18n?:any;
  reviewListNav?:any;
  backtoFeedback?:any;
}

interface S {
  // Customizable Area Start
  reviewsList: any;
  comment: string;
  activeData:{
    first: number,
    second: number,
    [key: string]: any;
  };
  sendRequestPop:boolean;
  successPopup:boolean;
  paginationData: PaginationData,
  perPage: string;
  customerReviews: Review[],
  loader: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class FeedbackController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];
    // Customizable Area Start
    this.state = {
      comment: "",
      sendRequestPop:false,
      paginationData: {
        current_page: 1,
        next_page: 2,
        total_pages: 0,
        total_count: 0
      },
      perPage: '10',
      customerReviews: [],
      successPopup:true,
      reviewsList: [],
      activeData: {
        first: 0,
        second: 1,
        key:2
      },
      loader: false,
    };
    // Customizable Area End
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  feedbackId: string = "";
  feedbackListId: string = "";

  async receive(from: string, message: Message) {
    this.handleReceivereviewList(from, message);
    this.handlePostFeedback(from ,message);
  }

  handleReceivereviewList = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.feedbackListId) {
      this.setState({loader: false})
      if(!apiResponse.errors) { 
        
        if (apiResponse && apiResponse.data) {
          try {
            const paginationData: PaginationData = {
              current_page: apiResponse.meta?.current_page || 0,
              next_page: apiResponse.meta?.next_page || 0,
              total_pages: apiResponse.meta?.total_pages || 0,
              total_count: apiResponse.meta?.total_count || 0,
            };
          
            const customerReviews: Review[] = apiResponse.data.data
              .map((review: any) => ({
                userName: `${review.attributes.account.data.attributes.first_name} ${review.attributes.account.data.attributes.last_name}`,
                image: review.attributes.account.data.attributes.profile_image?.url || "", // Default to empty if no image
                dateOfReview: review.attributes.created_at || "",
                reviewDescription: review.attributes.comment || "",
              }))
              .sort((a: any, b: any) => {
                // Convert date strings to Date objects for comparison
                const dateA = new Date(a.dateOfReview).getTime();
                const dateB = new Date(b.dateOfReview).getTime();
                return dateB - dateA; // Sort in descending order (newest first)
              });
          
            this.setState({
              paginationData,
              customerReviews,
            });
          }  catch (error) {
            toast.error("Error parsing response JSON");
            this.resetCustomReview()
          }
        } else {
          toast.error("Invalid response structure");
          this.resetCustomReview()
        }
      }
    }
  }

  resetCustomReview = () => {
    this.setState({
      paginationData: {
        current_page: 0,
        next_page: 0,
        total_pages: 0,
        total_count: 0,
      },
      customerReviews: [],
    });
  }

  handlePaginatioChange = (event: any, next: number) => {
    this.setState((prevState) => ({
      paginationData: {
        ...prevState.paginationData,
        current_page: next
      },
    }), this.handleListingFeedbackApi)
  }
  

  handlePostFeedback = (from: string, message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.feedbackId) {
      if(!apiResponse.errors) {
        toast.success(apiResponse.meta.message)
        setTimeout(() => {
         this.setState({sendRequestPop: true})
        }, 500);
        this.setState({successPopup: !this.state.successPopup})
      } else {
        if(Object.keys(apiResponse.errors[0]).includes('comment')) {
          toast.error(this.handleCondition(this.props.i18n?.language === 'ar', "لا يمكن أن يكون التعليق فارغًا","Feedback can not be blank"))
        }
        apiResponse.errors.forEach((item: string) => toast.error(item))
      }
      
    }
  }

  handleFeedbackPostApi = () => {
    const formResponseData = new FormData();
    formResponseData.append("feedback[comment]", this.state.comment);
    const header = {
      contentType: configJSON.contentTypeApiUpdateUser,
      token: localStorage.getItem("token"),
    };

    const feedbackData = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.feedbackId = feedbackData.messageId;

    feedbackData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.feedbackSubmitAPiEndPoint
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formResponseData
    );
    feedbackData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(feedbackData.id, feedbackData);
  };

  componentDidMount = async () => {
    this.handleListingFeedbackApi();
  };

  handleSendRequest =() => {
   this.setState({sendRequestPop:  !this.state.sendRequestPop})
  }

  sendRequestPopup = () => {
    this.setState({ sendRequestPop: false })
  }

  handleListingFeedbackApi = () => {
    const { paginationData, perPage } = this.state;
    const token = localStorage.getItem("token");
    const headers = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const feedbackDataa = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    this.feedbackListId = feedbackDataa.messageId;

    const params = new URLSearchParams();

  if (paginationData.current_page) params.append("page", paginationData?.current_page.toString());
  if (perPage) params.append("per_page", perPage);
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.feedbackListEndPoint}?${params.toString()}`
    );
    feedbackDataa.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(feedbackDataa.id, feedbackDataa);
    this.setState({loader: true})
  };


  displayDate = (date: Date) => {
    const currentDate:any = new Date();
    const inputDate:any = new Date(date);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
    const timeDiff = currentDate - inputDate;
    const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (dayDiff === 0) {
      return inputDate.toLocaleDateString();
    } 
     else if (dayDiff === 1) {
      return `${dayDiff} day ago`;
    }else if (dayDiff > 1) {
      return `${dayDiff} days ago`;
    }
  };

  sharePopupFeedback = () => {
    this.setState({successPopup: !this.state.successPopup})
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart;
  };

  handleAllReview = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "FeedbackAllReviews");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  handleFeedback = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Feedback");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area End
}
