import React from 'react';
import {
  makeStyles,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Divider,
  Button,
  Box,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { logoutColored } from '../../blocks/navigationmenu/src/assets';
import { useTranslation } from 'react-i18next';


interface LogoutModalProps {
  open: boolean;
  handleClose: () => void;
  handleLogout: () => void;
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2, 1.5),
    maxWidth: 400,
    width: '80%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 4, 3),
      width: '90%'
    },
    },
    closeIcon: {
      position: 'absolute',
      top: 14,
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
        right: 32
      },
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    headerText: {
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.005em",
    },
    bodyText: {
        fontFamily: "DIN Next LT Arabic Regular",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "26px",
        textAlign: "left",
        marginTop: '20px'

    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        flexDirection: 'column',
        gap: '15px',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    cancelButton: {
        "&:hover": {
          backgroundColor: `#1B4FE430`,
        },
        textTransform: 'none',
        borderColor: '#1B4FE4',
        borderRadius: '8px',
        color: '#1B4FE4',
        fontFamily: "DIN Next LT Arabic Bold",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "24px",
        textAlign: "left",
        width: '100%',
        padding: '16px',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
        },
    },
  logoutButton: {
    "&:hover": {
      backgroundColor: '#FEE2E2',
    },
    textTransform: 'none',
    borderColor: theme.palette.error.main,
    borderRadius: '8px',
    color: theme.palette.error.main,
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textAlign: "left",
    width: '100%',
    padding: '16px',
  },
}));

export default function LogoutModal({ open, handleClose, handleLogout }: LogoutModalProps) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { i18n } = useTranslation();

  return (
    <Modal
      aria-labelledby="logout-modal-title"
      aria-describedby="logout-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper} style={{direction: i18n?.language === 'ar' ? "rtl" : "ltr"}}>
          <div className={classes.header}>
            <Typography className={classes.headerText}
            style={{textAlign: i18n?.language === 'ar'? 'right' : 'left'}}>
              {i18n?.t('logoutAccount')}
            </Typography>
              <CloseIcon 
              onClick={handleClose} 
              className={classes.closeIcon} 
              style={{color: '#000',right: i18n?.language === 'ar'? "92%" : 22}} />
          </div>
          <Typography className={classes.bodyText} style={{textAlign: i18n?.language === 'ar' ? 'right' : 'left'}}>
            {i18n?.t('sureForLogout?')}
          </Typography>
          <Divider style={{margin: '20px 0'}}/>
          <Box className={classes.buttons}>
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              {i18n?.t('cancelforLogout')}
            </Button>
            <Button
              variant="outlined"
              className={classes.logoutButton}
              startIcon={<img src={logoutColored} style={{color: '#DC2626', paddingLeft: i18n?.language === 'ar' ? "12px" : ""}} />}
              onClick={() => handleLogout()}
            >
              {i18n?.t('logout')}
            </Button>
          </Box>
        </div>
      </Fade>
    </Modal>
  );
}
