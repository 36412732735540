export const imgArrow = require("../assets/arrow.png");
export const imgArrowSmall = require("../assets/arrow_small.png");
export const crossIconButton = require("../assets/crossIcon.svg")
export const imgUploadBoxLarge = require("../assets/imgUploadBoxLarge.svg");
export const imgUploadBoxSmall = require("../assets/imgUploadBoxSmall.svg");
export const imgAddress = require("../assets/address.svg");
export const imgIcon = require("../assets/icon.svg");
export const imgAddressSmall = require("../assets/addressSmall.svg");
export const imgIconSmall = require("../assets/iconSmall.svg");
export const downloadSmallIcon = require("../assets/download.svg");
export const deleteSmallIcon = require("../assets/delete.svg");
export const fullScreenIcon = require("../assets/fullscreen.svg");
export const carefulIcon = require("../assets/careful.svg");
export const imgArrow2 = require("../assets/bright.png");
export const imgUploadBoxLargeA = require("../assets/arabicE.jpg");

