import React, { useState } from 'react';
import { 
    Box, 
    Grid, 
    IconButton, 
    Typography,
    makeStyles, 
    Theme, 
    createStyles,
    Popover,
    Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { IFileOrFolder } from '../../blocks/documentation/src/DocumentationFolderController';
import Downloader from './Downloader.web';
import DeleteConfirmationPopup from './DeleteConfirmationPopup.web';
import FilePreview from './FilePreview.web';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        folder: {
            padding: theme.spacing(2),
            textAlign: 'center',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        fileIcon: {
            width: '100%',
            maxWidth: '100px',
            height: 'auto',
            margin: '6px auto',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '70px',
            }
        },
        fileName: {
            wordBreak: 'break-word',
            cursor: 'pointer',
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
        },
        fileSize: {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
        },
        popover: {
            padding: 0,
            width: '200px',
            maxWidth: '90vw',
            [theme.breakpoints.down('xs')]: {
              width: '180px',
            },
          },
        popoverButton: {
            fontFamily: "DIN Next LT Arabic Regular",
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '24px',
            justifyContent: 'flex-start',
            width: '100%',
            textTransform: 'none',
            transition: 'background-color 0.3s, color 0.3s',
            padding: '10px 16px',
            '&:hover': {
                backgroundColor: '#1B4FE4',
                borderRadius: 'inherit',
                color: theme.palette.primary.contrastText,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.contrastText,
                },
            },
        },
    })
);

interface FolderComponentProps {
    folderOrFile: IFileOrFolder;
    onViewFolder: (id: string) => void;
    onDeleteFileOrFolder: (id: string) => void;
    onEditFolder: (id: string, folderName: string) => void;
    renderFileIcon: (type: string) => string;
    downloadDocument: (id: string) => void;
}

const FolderComponent: React.FC<FolderComponentProps> = ({
    folderOrFile,
    onViewFolder,
    onDeleteFileOrFolder,
    onEditFolder,
    renderFileIcon,
    downloadDocument
}) => {
    const classes = useStyles();
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<IFileOrFolder | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const openDeletePopup = () => setDeletePopupOpen(true);
    const closeDeletePopup = () => setDeletePopupOpen(false);

    const handleConfirmDelete = () => {
        onDeleteFileOrFolder(folderOrFile.id);
        closeDeletePopup();
    };

    const handleItemClick = () => {
        if (folderOrFile.type === 'folder') {
            onViewFolder(folderOrFile.id);
        } else {
            setSelectedFile(folderOrFile);
            setIsOpen(true);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleRename = () => {
        onEditFolder(folderOrFile.id, folderOrFile.name);
        handlePopoverClose();
    };

    const handleDeleteClick = () => {
        setDeletePopupOpen(true);
        handlePopoverClose();
    };

    const fileIcon: string = renderFileIcon(folderOrFile.type || '');

    const open = Boolean(anchorEl);

    const { i18n } = useTranslation();

    return (
        <Grid item xs={6} sm={4} md={3}>
            <Box className={classes.folder}>
                <div>
                    <img
                        src={fileIcon}
                        onClick={handleItemClick}
                        className={classes.fileIcon}
                        style={{ cursor: 'pointer' }}
                        alt={folderOrFile.name}
                    />
                    <Typography 
                        variant="subtitle2" 
                        className={classes.fileName}
                        onClick={handlePopoverOpen}
                    >
                        {folderOrFile.name}
                    </Typography>
                    {folderOrFile.type === 'folder' ? (
                        <Typography variant="body2" color="textSecondary" className={classes.fileSize}>
                            {folderOrFile.items} Items
                        </Typography>
                    ) : null}
                </div>
            </Box>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                classes={{
                    paper: classes.popover,
                  }}
                  marginThreshold={16}
            >
                <Box className={classes.popover} style={{direction: i18n?.language === 'ar'? 'rtl': 'ltr'}}>
                    <Button
                        startIcon={<EditIcon  style={{paddingLeft: i18n?.language === 'ar'? "10px" : ''}}/>}
                        onClick={handleRename}
                        className={classes.popoverButton}
                    >
                        {i18n?.t('rename')}
                    </Button>
                    {folderOrFile.type !== 'folder' && (
                        <Button
                        startIcon={<GetAppIcon style={{paddingLeft: i18n?.language === 'ar'? "10px" : ''}}/>}
                        onClick={() => downloadDocument(folderOrFile.id)}
                        className={classes.popoverButton}
                        style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {i18n?.t('download')}
                      </Button>
                    )}
                    <Button
                        startIcon={<DeleteIcon style={{paddingLeft: i18n?.language === 'ar'? "10px" : ''}}/>}
                        onClick={handleDeleteClick}
                        className={classes.popoverButton}
                    >
                        {i18n?.t('delete')}
                    </Button>
                </Box>
            </Popover>
            {selectedFile && <FilePreview isOpen={isOpen} onClose={handleClose} file={selectedFile} />}
            <DeleteConfirmationPopup
                open={deletePopupOpen}
                onClose={closeDeletePopup}
                onConfirm={handleConfirmDelete}
                itemName={folderOrFile.name}
                itemId={folderOrFile.id}
            />
        </Grid>
    );
};

export default FolderComponent;