// Customizable Area Start
import React from "react";
import { Box, Button, MenuItem, Select, TextField, Typography, createTheme, styled } from "@material-ui/core";
import MultipageFormsControllerWeb, {
  FormIn,
  Props,
} from "./MultipageFormsController.web";
import { bRight, backIcon, calendar, cross2, crossIcon, pdfImage, pngImage, smlIcon, uploadIcon } from "./assets";
import { Formik } from "formik";
import { withTranslation } from "react-i18next";

interface FormValues {
  email: string;
  account: string;
  remarks: string;
  transactionId: string;
  acoount_name: string;
  transaction_purpose: string;
  filename: string;
  file: File | null;
}
// Customizable Area End

export default class MultipageForms extends MultipageFormsControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .mainContainer": {
      [theme.breakpoints.down(598)]: {
        padding: "40px 20px",width: "calc(100% - 40px)"},
      width: "calc(100% - 240px)",height: "fit-content",padding: "40px 120px",
      display: "flex",flexDirection: "column", alignItems: "flex-start",justifyContent: "flex-start",
      gap: "40px"},
    "& .backIcon": {
      [theme.breakpoints.down(598)]: {
      },
      position: "relative",
      left: "20px"
    },
    "& .formContainer": {
      [theme.breakpoints.down(598)]: {
      gap: "16px",
      width: "100%",
      padding: "0px",
      },
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "40px",
    },
    "& .heading": {
      [theme.breakpoints.down(598)]: {
        fontSize: "20px",
      },
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "30px",
      textAlign: "center"
    },
    "& .form": {
      [theme.breakpoints.down(598)]: {
        
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "25px",
    },
    "& .labelInputBox": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "4px",
    },
    "& .labelInputBox2": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "4px",
    },
    "& .label": {
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px", color: "#334155",
    },
    "& .star": {
      fontFamily: "DIN Next LT Arabic Bold",
      fontSize: "16px",
      color: "#DC2626",
      marginLeft: "5px",
    },
    "& .nameInputText": {
      [theme.breakpoints.down(598)]: {
        width: "100%"
      },
      borderRadius: "8px",
      width: "100%",
      fontFamily: "DIN Next LT Arabic Light",
      fontWeight: 400,
      fontSize: "16px",
      "& .MuiInputBase-input.Mui-disabled": {
        color: '#000'
      }
    },
    "& .dateInput": {
      [theme.breakpoints.down(598)]: {},
      width: "100%",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
      border: "none",
    },
    "& .dateBox": {
      [theme.breakpoints.down(598)]: {},
      width: "calc(100% - 24px)",
      display: "flex",
      padding: "12px",
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Regular",
      outline: "none",
    },
    "& .selectDate": {
      width: 0,
      border: "none",
    },
    "& .imgBox2": {
      [theme.breakpoints.down(598)]: {
        width: "97%",
      },
      border: "1px solid #B0BACD",
      color: "#0F172A",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row" as "row",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "2rem",
      padding: "10px 8px",
      background: "#CBD5E1",
      height: "35px",
      width: "calc(100% - 16px)"
    },
    "& .imgBox": {
      [theme.breakpoints.down(598)]: {
        width: "100%",
      },
      border: "1px dashed #B0BACD",
      borderRadius: "16px",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "1rem",
      padding: "1rem 0",
      width: "100%",
    },
    "& .sendAfterRequest" : {
      [theme.breakpoints.down(598)]: {
        width: "95%",
      },
      color: "#FFFFFF",
      padding: "16px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      backgroundColor: "#1B4FE4",
      borderRadius: "8px",
      gap: "8px",
      width: "30%",
      textTransform: "none",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& .sendRequest" :{
      [theme.breakpoints.down(598)]: {
        width: "95%",
      },
      color: "#64748B",
      padding: "16px",
      fontSize: "16px",
      fontFamily: "DIN Next LT Arabic Bold",
      backgroundColor: "#F1F5F9",
      borderRadius: "8px",
      gap: "8px",
      width: "30%",
      textTransform: "none",
      boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)",
    },
    "& .backBox" :{
      [theme.breakpoints.down(938)]: {

        width: "calc(55% - 48px)",
      },
      [theme.breakpoints.down(853)]: {

            width: "calc(60% - 48px)",
      },
      [theme.breakpoints.down(782)]: {
          width: "calc(65% - 48px)",      },
      [theme.breakpoints.down(722)]: {        width: "calc(70% - 48px)",
      },
      [theme.breakpoints.down(670)]: {
    width: "calc(75% - 48px)",
      },
      [theme.breakpoints.down(626)]: {
        width: "calc(80% - 48px)"
      },
      [theme.breakpoints.down(598)]: {
        width: "90%"
      },
      width: "calc(40% - 48px)",
    },
    "& .successContainer" :{
      [theme.breakpoints.down(938)]: { width: "calc(55% - 48px)",
      },      [theme.breakpoints.down(853)]: {
        width: "calc(60% - 48px)",
      },[theme.breakpoints.down(782)]: {
        width: "calc(65% - 48px)",
      },[theme.breakpoints.down(722)]: {width: "calc(70% - 48px)",
      },
      [theme.breakpoints.down(670)]: {
        width: "calc(75% - 48px)",      },
           [theme.breakpoints.down(626)]: {width: "calc(80% - 48px)"  },
      [theme.breakpoints.down(598)]: {width: "90%"},
      width: "calc(40% - 48px)"
    },
    "& .crossStyle" :{
      [theme.breakpoints.down(598)]: {
        width: "40px" ,
        height: "40px"},
    },
    "& .mobileText" :{
      [theme.breakpoints.up(598)]: {
        display: "none"
      },
      [theme.breakpoints.down(598)]: {
        display: "flex",
        justifyContent: "space-between", gap: "5px"},
    },
    "& .surefor" :{[theme.breakpoints.down(598)]: {display: "none"}},
    "& .sureForMobile" :{
      [theme.breakpoints.down(598)]: {
        fontSize: "18px",
        fontFamily: "DIN Next LT Arabic Bold",
        color: "#334155"
      },
    },
    "& .successBox" :{
      [theme.breakpoints.down(598)]: {padding: "40px 24px 15px 25px", gap: "8px"},
      display: "flex",      flexDirection: "column",
      gap: "24px",      padding: "60px 40px 15px 40px"    },
    "& .crossIcon" :{
      [theme.breakpoints.down(598)]: {
        display: "none"
      },
    },
    "& .viewStatus" :{
      [theme.breakpoints.down(598)]: {
        width: "50%"
      },
      width: "30%",
    },
    "& .viewBox" :{
      [theme.breakpoints.down(598)]: {width: "calc(90% - 20px)", padding: "20px 0 20px 20px"},
      padding: "20px",
      width: "calc(100% - 40px)"
    },
    "& .accountInfo": {
      [theme.breakpoints.down(598)]: {
        width: "calc(100% - 26px)",
      },
      width: "calc(100% - 26px)",
      backgroundColor: "#F1F5F9",
      display: "flex",      justifyContent: "flex-start",
      alignItems: "center",      gap: "8px",
      borderRadius: "4px", padding: "8px 20px 8px 6px"},
    "& .nameInfo": {
      fontSize: "12px",      fontFamily: "DIN Next LT Arabic Regular",
      color: "#334155",    },
    "& .backfor": {
      [theme.breakpoints.down(598)]: {
      fontSize: "14px",
      },
      fontSize: "18px",
      fontFamily: "DIN Next LT Arabic Regular",  
      color: "#475569",
    },
    "& .buttonBox": {
      [theme.breakpoints.down(598)]: {
      width: "calc(90% - 40px)",
      },
      width: "calc(100% - 40px)",
    },
    "& .textBox": {
      [theme.breakpoints.down(598)]: {
        padding: "16px 24px"
      },
      padding: "40px 40px 15px 40px",
      display:"flex",
      flexDirection:"column",
      gap:"16px"
    },
  }));

  // Helper function to render inputLabel
  renderInputLabelBox = (item: FormIn, values: FormValues, touched: any, errors: any, handleBlur: any, setFieldValue: any) => {
    return <Box className="labelInputBox" key={item.name}>
    <Typography className="label">
     {this.props.i18n.t(item.label)}
      {item.isMandatory && <span className="star">*</span>}
    </Typography>
    <TextField
      disabled={['email', 'account'].includes(item.name)}
      type={item.type}
      className="nameInputText"
      placeholder= {this.props.i18n.t(item.placeholder)}
      name={item.name}
      variant="outlined"
      onBlur={handleBlur}
      value={values[item.name as keyof FormValues]}
      onChange={(event) => setFieldValue(item.name, event.target.value)}
      error={touched[item.name] && Boolean(errors[item.name])}
    />
    {this.getErrorMessage(touched, errors, item.name)}
    {item.name === "acoount_name" ? (
      <Box className="accountInfo">
          <img src={smlIcon} />
            <Typography className="nameInfo">
            {this.props.i18n?.t('exactlyTheEnglishNameMsg')}
            </Typography>
      </Box>
      ) : (
      ""
    )}
  </Box>
  }

  // Helper function to render file upload
  renderFileUploadBox = (values: FormValues, touched: any, setFieldValue: any, setTouched: any) => {
    return (
      <Box width={'100%'}>
        {values.file ? (
          <Box className="imgBox2" style={{
            borderColor: this.state.fileErrorMsg ? 'red' : '#B0BACD',
            borderWidth: '1px',
            borderStyle: 'solid',
          }}>
            <Box style={webStyle2.pdfBox}>
              {values.filename.includes(".pdf") ? (
                <img
                  src={pdfImage}
                  alt="PDF icon"
                  style={webStyle2.pdfStyle}
                />
              ) : (
                <img
                  src={pngImage}
                  alt="Image icon"
                  style={webStyle2.pdfStyle}
                />
              )}
              <Typography style={webStyle2.fileSty}>{values.filename || ""}</Typography>
            </Box>
            <img
              data-test-id="cross"
              src={crossIcon}
              onClick={() => {
                setFieldValue("file", null)
                setFieldValue("filename", "")
                this.validateFile(null)
              }}
            />
          </Box>
        ) : <Box
          className="imgBox"
          role="button"
          onClick={this.handleClick}
          style={{
            borderColor: this.state.fileErrorMsg ? 'red' : '#B0BACD',
            borderWidth: '1px',
            borderStyle: 'dashed',
          }}
        >
          <input
            type="file"
            data-test-id="files"
            name="file"
            accept=".pdf, .jpg, .jpeg, .png"
            style={webStyle2.fileStyle}
            ref={this.state.inputRef}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>
            ) => {
              const selectedFile = event.target.files?.[0];
              if (selectedFile) {
                setFieldValue("file", selectedFile);
                setFieldValue("filename", selectedFile.name);
                this.validateFile(selectedFile)
                // Mark the file field as touched
                setTouched({ ...touched, file: true });
              }
            }}
          />
          <>
            <img src={uploadIcon} style={webStyle2.fileupLoad} />
            <Typography style={webStyle2.uploadText1}>
            {this.props.i18n?.t('upload_transaction_slip')}         
            </Typography>
            <Typography style={webStyle2.uploadText2}>
            {this.props.i18n?.t('image_pdf_png')} 
            </Typography>
          </>
        </Box>
        }
        {this.state.fileErrorMsg && (
          <Typography style={{
            marginTop: "7px",
            fontSize: "12px",
            color: "#DC2626",
            fontFamily: "DIN Next LT Arabic Regular",
          }} color="error">{this.state.fileErrorMsg}</Typography>
        )}
      </Box>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
    const initialValues: FormValues = {
      email: this.state.contractUserEmail,
      account: this.state.contractAmouont,
      remarks: '',
      transactionId: '',
      acoount_name: this.state.secondPartyName,
      transaction_purpose: '',
      filename: '',
      file: null,
    };
    return (
      <this.MainWrapper>
        <Box className="mainContainer" style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr')}}>
          <img
                  src={this.handleCondition(this.props.i18n?.language === 'ar',bRight,backIcon)} 
           className="backIcon"
           style={{
            width: this.handleCondition(this.props.i18n?.language === 'ar', "27px","20px"),
           }}
            onClick={this.handleOpenPopup}/>
          {this.state.backPopup && (
            <Box style={{...webStyle2.boxContainer,
             right: this.handleCondition(this.props.i18n?.language === 'ar' , '14px', ''),
             left:this.handleCondition(this.props.i18n?.language === 'ar', '15px', '')}}>
              <Box sx={webStyle2.backBox} className="backBox">
                <Box className="textBox">
                <Box className="mobileText">
                  <Typography className="sureForMobile">{this.handleTranslationChange('areYouSureYouWantToGoBAck')} </Typography>
                  <img src={cross2} onClick={this.handleOpenPopup} className="crossStyle"/>
                </Box>
                  <Typography style={webStyle2.surefor} className="surefor"> {this.handleTranslationChange('areYouSureYouWantToGoBAck')}</Typography>
                  <Typography className="backfor">{this.handleTranslationChange('sure_for_back_changes_not_saved')}</Typography>
                </Box>
                <Box style={webStyle2.buttonBox} className="buttonBox">
                  <button style={webStyle2.cancel} onClick={this.handleOpenPopup}>{this.handleTranslationChange('cancelForBack')}</button>
                  <button style={webStyle2.back}>
                  <Typography onClick={this.handleBackClick} style={webStyle2.linkColor}>
                  {this.handleTranslationChange('backForBack')}
                  </Typography> 
                    </button>
                </Box>
              </Box>
            </Box>
          )}
          {this.state.sendRequest && (
            <Box style={webStyle2.boxContainer}>
              <Box sx={webStyle2.successContainer} className="successContainer">
                <Box className="successBox">
                  <Box className="mobileText">
                    <Typography className="sureForMobile"> {this.handleTranslationChange('successfullySentRequest')}</Typography>
                  </Box>
                  <Typography style={webStyle2.surefor} className="surefor">{this.handleTranslationChange('successfullySentRequest')}</Typography>
                  <Typography className="backfor">{this.handleTranslationChange('successfullySentRequestMsg')}</Typography>
                </Box>
                <Box onClick={this.handleBackClick} style={webStyle2.viewBox} className="viewBox">
                  <button style={webStyle2.viewStatus} className="viewStatus" onClick={this.depositRequest}>{this.handleTranslationChange('viewStatus')}</button>
                </Box>
              </Box>
            </Box>
          )}
          <Box className="formContainer">
            <Typography className="heading">{this.handleTranslationChange('deposit_request')}</Typography>
            <Formik
              data-test-id="formik"
              enableReinitialize={true}
              validationSchema={this.handleCondition(this.props.i18n?.language === 'ar', this.depositSchemaAr, this.depositSchema)}
              initialValues={initialValues}
              onSubmit={(values) => this.depositFormPost(values)}
            >
              {({
                handleSubmit,
                errors,
                values,
                handleBlur,
                touched,
                setFieldValue,
                setTouched
              }) => <form className="form" onSubmit={handleSubmit} autoComplete="off">
               {this.state.form.map((item) =>
              this.renderInputLabelBox(item, values, touched, errors, handleBlur, setFieldValue)
            )}
              <Box style={webStyle2.nameInput} className="labelInputBox">
                <Typography className="label">
                {this.handleTranslationChange('purpose_of_transaction')}
                  <span className="star">*</span>
                </Typography>
                <Select
                  variant="outlined"
                  name="transaction_purpose"
                  className="nameInputText"
                  fullWidth
                  data-test-id="service"
                  value={values.transaction_purpose || "none"} 
                  onBlur={handleBlur}
                  onChange={(event) =>
                    setFieldValue("transaction_purpose", event.target.value)
                  }
                  error={touched.transaction_purpose && Boolean(errors.transaction_purpose)}
                  style={{
                      borderColor: touched.transaction_purpose && errors.transaction_purpose ? 'red' : 'inherit',
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        direction: this.handleCondition(this.props.i18n?.language === 'ar', 'rtl', 'ltr'),
                      },
                    },
                  }}
                
                >
                  <MenuItem value="none" disabled>
                  {this.handleTranslationChange('purpose_of_transaction')}
                  </MenuItem>
                  {this.state.transactionOptions.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
                {this.getErrorMessage(touched, errors, "transaction_purpose")}
              </Box>
              {this.renderFileUploadBox(values, touched, setFieldValue, setTouched)}
              <Box className="labelInputBox">
                  <Typography className="label">
                  {this.handleTranslationChange('preferredDate')}
                    <span className="star">*</span>
                  </Typography>
                  <Box className="dateBox">
                    <input
                     style={{direction: this.handleCondition(this.props.i18n?.language === 'ar', 'ltr', ''), textAlign: this.handleCondition(this.props.i18n?.language === 'ar', 'right', '')}}
                      type="text"
                      className="dateInput"
                      id="formattedDate"
                      name="formattedDate"
                      placeholder={this.handleTranslationChange('preferred_date')}
                      value={this.state.formattedDate}
                    />
                      <img
                        src={calendar}
                        className="calendarIcon"
                      />
                    </Box>
              </Box>
              <Box className="labelInputBox2">
                <Button 
                className={
                  this.handleCondition(
                    !Object.entries(values).some(([key, value]) => 
                      key !== "remarks" && (value === undefined || value === null || value.length === 0)
                    ),
                    "sendAfterRequest",
                    "sendRequest"
                  )
                } 
                data-test-id="disabledClick"
                type="submit"
                disabled={!this.handleDisableCondition(errors, values)}
                >
                  {this.state.disabledAfterSend ? 
                  this.handleTranslationChange('please_wait') :
                   this.handleTranslationChange('sendRequestBtn')}
                </Button>
              </Box>
            </form>}
            </Formik>
          </Box>
        </Box>
      </this.MainWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const MultipageFormsWebWithTranslation = withTranslation()(MultipageForms)
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const webStyle2 = {
  mainContainer: {
    display: "flex",
  },
  fileSty:{ width: "100%"},
  pointer: { cursor: "pointer" },
  imgContainer: {
    height: "auto",
    width: "50%",
    backgroundColor: "#122A6A",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "4rem",
    paddingBottom: "4rem",
    paddingTop: "4rem",
  },
  imgStyle: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "flex-start",
  },
  signIn: {
    color: "#1B4FE4",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  imgCon: { width: "100%" },
  alreadyStyle: {
    display: "flex",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  passwordCalculator: {
    display: "flex",
    alignItems: "flex-start",
    width: "100%",
    gap: "8px",
  },
  img: { width: "60%", marginBottom: "3rem" },
  passwordCalBox: {
    backgroundColor: "",
    display: "flex",
    gap: "5px",
    width: "100%",
  },
  passwordContent: { width: "100%", height: "10px", borderRadius: "10px" },
  passwordContentBox: {
    width: "37%",
    marginBottom: "15px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  pdfStyle: { width: "24px", height: "24px" },
  passwordInput: { border: "none", outline: "none" },
  pdfBox: { display: "flex", alignItems: "center", gap: "10px", width: "100%"},
  imgText: {
    color: "#F4F4EA",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    gap: "1rem",
  },
  imgText2: {
    textAlign: "center" as "center",
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  imgText1: {
    textAlign: "center" as "center",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  signUp: {
    fontWeight: 400,
    fontSize: "14px",
    width: "89%",
    border: "1px solid #E2E8F0",
    boxShadow: "0px 2px 8px 0px #00000014",
  },
  signupText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  signWithGoogle: {
    textTransform: "none" as "none",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  imgPopUpText: {
    fontSize: "12px",
    fontWeight: 400,
    color: "#1E293B",
    lineHeight: "18px",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  logo: { width: "20rem" },
  fileStyle: { display: "none" },
  container: {
    height: "auto",
    alignItems: "center",
  },
  uploadText1: {
    fontSize: "16px",
    fontWeight: 700,
    color: "#1E293B",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  fileupLoad: { width: "50px" },
  uploadText2: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  BoxLine: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "89%",
  },
  line: {
    flex: "1",
    height: "1px",
    backgroundColor: "#CBD5E1",
  },
  validationStyle: {
    marginTop: "2px",
    fontSize: "14px",
    color: "#f94b4b",
  },
  orstyle: {
    color: "#64748B",
    textAlign: "center" as "center",
    padding: "10px",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  mainHeading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "ceter",
    textAlign: "center" as "center",
    fontWeight: 700,
    width: "90%",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  nameContainer: {
    display: "flex",
    alignItems: "flex-start",
  },
  formData: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  },
  nameInput: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "6px",
  },
  passwordIconStyle: { width: "1rem", height: "1rem" },
  labeltext: {
    display: "flex",
    color: "#334155",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  passwordBoxx: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: "31",
    padding: "24px",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  passBox: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "8px",
    alignItems: "flex-end",
  },
  passBox2: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    gap: "16px",
  },
  passText: {
    fontSize: "20px",
    color: "#0F172A",
    lineHeight: "28px",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  redIcon: { color: "red" },
  inputText: {
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "16px",
    padding: "0",
  },
  passInputText: {
    borderRadius: "8px",
    fontWeight: 400,
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    justifyContent: "space-between",
    padding: "0.8rem",
  },
  phoneSelectStyle: {
    backgroundColor: "#EFF6FF",
    width: "4.75rem",
  },
  phoneInputText: {
    borderRadius: "8px",
    fontWeight: 400,
    border: "1px solid #CBD5E1",
    padding: "0.8rem",
    fontSize: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  phoneInputStyle: {
    border: "none",
    outline: "none",
    padding: "0.5rem 1rem",
  },
  nameInputText: {  },
  checkboxtextWithError: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    gap: "1rem",
  },
  agreeText: {
    gap: "0.2rem",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  checkboxText: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    gap: "1rem",
  },
  checkboxColor: { color: "#1B4FE4", width: "20px", height: "20px" },
  hightlightText: {
    fontWeight: 700,
    color: "#1948CB",
    fontFamily: "DIN Next LT Arabic Bold",
    cursor: "pointer",
    textDecoration: "none",
  },
  SubmitBtn: {
    width: "89%",
    backgroundColor: "#1B4FE4",
    color: "white",
    fill: "red",
    textTransform: "none" as "none",
    fontFamily: "DIN Next LT Arabic Bold",
  },
  termAndConditionError: {
    borderRadius: "4px",
    borderLeft: "4px solid #DC2626",
    padding: "1.6% 1.4%",
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    fontFamily: "DIN Next LT Arabic Regular",
    fontSize: "14px",
  },
  boxContainer: {
    width: "100%",
    position: "fixed" as "fixed",
    top: 0,
    right: 0,
    height: "100%",
    zIndex: 30,
    display: "flex",
    justifyContent: "center" as "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.6)"
  },
  backBox: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "24px",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: 31,
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  successContainer: {
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFF",
    top: 0,
    zIndex: "31",
    boxShadow: "0px 8px 32px 0px #0000000F,box-shadow: 0px 4px 8px 0px #00000008,box-shadow: 0px 25px 50px 0px #00000017",
  },
  iconBox: {width: "95%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end", paddingTop: "20px"},
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center" as "center",
    gap: "15px",
    padding: "20px",
    borderTop: "1px solid #E2E8F0"
  },
  viewBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    gap: "15px",
    borderTop: "1px solid #E2E8F0"
  },
  cancel: {
    padding: "16px 0 16px 0",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#FFFFFF",
    color: "#1B4FE4",
    fontSize: "16px",
    fontFamily: "DIN Next LT Arabic Bold",
    width: "50%"
  },
  back: {
    padding: "16px 0 16px 0",
    paddingTop: "16px",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#1B4FE4",
    color: "#FFFFFF",
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "16px",
    width: "50%"
  },
  linkColor: {color: "#FFFFFF", textDecoration: "none"},
  viewStatus: {
    padding: "16px 0 16px 0",
    paddingTop: "16px",
    borderRadius: "8px",
    border: "1.5px solid #1B4FE4",
    background: "#1B4FE4",
    color: "#FFFFFF",
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "16px",
  },
  surefor: {
    fontSize: "24px",
    fontFamily: "DIN Next LT Arabic Bold",
  },
};
// Customizable Area End
