export const backIcon = require('../assets/backArrow.svg');
export const downArrow = require('../assets/backArrow.svg');
export const infoIcon = require('../assets/backArrow.svg');
export const uploadIcon = require('../assets/uploadIcon.svg');
export const crossIcon = require('../assets/crossIcon.svg');
export const pdfImage = require("../assets/pdfImage.png");
export const pngImage = require("../assets/png.png");
export const exIcon = require("../assets/EblackIcon.svg");
export const dropDown = require("../assets/dropDown.svg");
export const smlIcon = require("../assets/ExIcon.svg");
export const calendar = require("../assets/calIcon.svg");
export const cross2 = require('../assets/MobileCross.svg');
export const approvedIcon = require("../assets/approved.svg");
export const rejectedIcon = require("../assets/rejected.svg");
export const contactSubmitInitIcon = require("../assets/contact_submit_init.svg");
export const contactReviewInitIcon = require("../assets/contact_review_init.svg");
export const contactReviewLaterIcon = require("../assets/contact_review_later.svg");
export const formFillInitIcon = require("../assets/form_fill_init.svg");
export const formFillLaterIcon = require("../assets/form_fill_later.svg");
export const formReviewInitIcon = require("../assets/form_fill_review.svg");
export const formReviewLaterIcon = require("../assets/contact_review_init.svg");
export const searchIcon = require("../assets/search.svg");
export const bRight = require("../assets/bright.png");



