import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController";
import {
  Box,
  Button,
  Link,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import {
  logo,
  mobileImg,
  leftIcon,
  rightIcon,
  mailIcon,
  callIcon,
  downIcon,
  upIcon,
  copywrite,
  facebook,
  twitter,
  instagram,
  youtube,
  whitebg,
  arIcon,
} from "./assets";
import Interactivecalculator from "../../../blocks/interactivecalculator/src/Interactivecalculator.web";
import LanguageSwitch from "../../../components/src/LanguageSwitch.web";
import { withTranslation } from "react-i18next";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getLandingPageDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .header": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "row",
        height: "8vw",
        alignItems: "center",
        paddingTop: "10px"
      },
      alignItems: "center",
      height: "fit-content",
    },
    "& .linkBox": {
      [theme.breakpoints.down(480)]: {
        display: "none",
      },
    },
    "& .link": {
      [theme.breakpoints.down(480)]: {
        display: "none",
      },
    },
    "& .MenuIcons": {
      [theme.breakpoints.down(480)]: {
        display: "flex",
      },
      display: "none",
    },
    "& .headerLogo": {
      [theme.breakpoints.down(480)]: {
        width: "20vw",
      },
      width: "10vw",
    },
    "& .signinButton": {
      [theme.breakpoints.down(480)]: {
        padding: "1vw 2vw",
        fontSize: "2.5vw",
        borderRadius: "1.2vw",
        fontWeight: 500,
      },
      padding: "0.4vw 1.8vw 0.6vw",
      fontSize: "1vw",
      borderRadius: "0.4vw",
      fontWeight: 700,
    },
    "& .buttonBox": {
      [theme.breakpoints.down(480)]: {
        gap: "2vw",
      },
      gap: "0.5vw",
    },
    "& .signupButton": {
      [theme.breakpoints.down(480)]: {
        padding: "1vw 2vw",
        fontSize: "2.5vw",
        borderRadius: "1.2vw",
        fontWeight: 500,
      },
      padding: "0.4vw 1.8vw 0.6vw",
      fontSize: "1vw",
      borderRadius: "0.4vw",
      fontWeight: 700,
    },
    "& .container1": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column",
      },
    },
    "& .leftBox": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        height: "70vw",
        border: "2vw solid white",
      },
      width: "28vw",
      border: "1.4vw solid white",
    },
    "& .leftInnerBox": {
      [theme.breakpoints.down(480)]: {
        width: "65vw",
        height: "62vw",
        left: "-4.5vw",
        top: "2vw",
        padding: "3vw",
      },
      height: "21vw",
      width: "26vw",
      top: "1.5vw",
      padding: "2vw",
    },
    "& .heading": {
      [theme.breakpoints.down(480)]: {
        fontSize: "2vw",
      },
    },
    "& .headText": {
      [theme.breakpoints.down(480)]: {
        fontSize: "8vw",
      },
      fontSize: "3vw",
    },
    "& .subHeading": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",
      },
      fontSize: "1vw",
    },
    "& .calculateBtn": {
      [theme.breakpoints.down(480)]: {
        fontSize: "2vw",
        width: "40vw",
        borderRadius: "1vw",
        padding: "2vw",
      },
      fontSize: "0.8vw",
      width: "20vw",
      borderRadius: "0.4vw",
      padding: "1vw",
    },
    "& .mobileImg": {
      [theme.breakpoints.down(480)]: {
        width: "56vw",}, width: "30vw",
    },
    "& .aboutContainer": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column", paddingTop: "3vw", paddingBottom: "0.1vw",
      },
      paddingTop: "11vw",paddingBottom: "10vw",
      direction: this.props.i18n.language === 'ar' ? 'rtl' : 'ltr'
    },
    "& .aboutBox": {
      [theme.breakpoints.down(480)]: {
        width: "100vw",}, width: "34vw",
    },
    "& .aboutBox2": {
      [theme.breakpoints.down(480)]: {
        fontSize: "8vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "4vw",
      },fontSize: "3vw",
      display: "inline-flex",
      alignItems: "flex-start",
    },
    "& .aboutType": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4.6vw",
        paddingTop: "2vw",
      },fontSize: "1.6vw",
    },
    "& .aboutExp": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4.2vw",
      },
      fontSize: "1vw",
    },
    "& .aboutExpHead": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",
      },
      fontSize: "1vw",
    },
    "& .aboutComType": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",
      },
      fontSize: "1.3vw",
    },
    "& .aboutCompany": {
      [theme.breakpoints.down(480)]: {
        alignItems: "center",
        gap: "3vw",
      },
      gap: "3.4vw",
    },
    "& .aboutCompanyBox": {
      [theme.breakpoints.down(480)]: {
        alignItems: "center",
      },
      alignItems: "flex-end",
    },
    "& .flexColend": {
      [theme.breakpoints.down(480)]: {
        alignItems: "center",
      },
      alignItems: "flex-end",
    },
    "& .plusType": {
      [theme.breakpoints.down(480)]: {
        fontSize: "7vw",
      },fontSize: "3.5vw",
    },
    "& .twoType": {
      [theme.breakpoints.down(480)]: {
        fontSize: "7vw", },fontSize: "3.5vw",
    },
    "& .OurPortBox": {
      [theme.breakpoints.down(480)]: {
        fontSize: "7vw",},
      fontSize: "3.5vw",
    },
    "& .OurPortType": {
      [theme.breakpoints.down(480)]: {
        fontSize: "5vw",
        paddingTop: "2vw",},fontSize: "1.8vw",
    },
    "& .OurPortText": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",
        width: "91vw",
        padding: "0 4vw", }, fontSize: "1vw",
      width: "45vw",
    },
    "& .OurImgBox": {
      [theme.breakpoints.down(480)]: {
        display: "none",}, display: "inline-flex",
    },
    "& .Our2": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column",},
    },
    "& .imageone": {
      [theme.breakpoints.down(480)]: {
        display: "none",
      },},
    "& .imagetwo": {
      [theme.breakpoints.down(480)]: {
        borderRadius: "5vw",
        width: "90vw",
        height: "100vw",},
      borderRadius: "1vw",
      width: "22vw",
    },
    "& .imagethree": {
      [theme.breakpoints.down(480)]: {
        display: "none",},
    },
    "& .OurImgg": {
      [theme.breakpoints.down(480)]: {
        display: "none",},display: "inline-flex",
    },
    "& .contentBoxofImage2": {
      [theme.breakpoints.down(480)]: {
        width: "80vw",
        height: "30vw",
        gap: "2vw",
      },
      width: "18vw",
      height: "13vw",
    },
    "& .imagetwoBox": {
      [theme.breakpoints.down(480)]: {
        height: "95vw",}, height: "34vw",
    },
    "& .contentTypeone": {
      [theme.breakpoints.down(480)]: {
        fontSize: "2.5vw",}, fontSize: "1vw",
    },
    "& .contentTypeTwo": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4.5vw",
        width: "55vw",
        paddingBottom: "1vw",},fontSize: "1vw",
    },
    "& .contentButton": {
      [theme.breakpoints.down(480)]: {
        width: "fit-content",
        borderRadius: "1vw",
      },
      width: "10vw",borderRadius: "0.4vw",
    },
    "& .contentButtonViewMore": {
      [theme.breakpoints.down(480)]: {
        width: "fit-content",
        borderRadius: "1vw",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3)
      },
      width: "10vw",borderRadius: "0.4vw",
      marginTop: theme.spacing(3),
    },
    "& .contentButtonStyle": {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      [theme.breakpoints.down(900)]: {
        fontSize: "1vw",
        alignItems: 'center',
      },
      [theme.breakpoints.down(480)]: {
        fontSize: "2vw",
      },
      fontSize: "1vw",
    },
    "& .arrowStyle": {
      [theme.breakpoints.down(480)]: {
        width: "4vw",
        marginLeft: "4vw",
        padding: "0",}, width: "2vw",
    },
    "& .OurImgBox2": {
      [theme.breakpoints.down(480)]: {
        marginTop: "2vw",
        paddingBottom: "2vw",
      }, marginTop: "1vw",
    },

    "& .activeCircle": {
      [theme.breakpoints.down(480)]: {
        width: "2vw",
        height: "2vw",
      },
      width: "1.5vw",
      height: "1.5vw",
      background: "#132E79 !important",
    },
    "& .circlePortfolio": {
      [theme.breakpoints.down(480)]: {
        width: "1.5vw",
        height: "1.5vw",
      }, width: "1vw",
      height: "1vw",
    },
    "& .investHeading": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",paddingTop: "4vw", },
      fontSize: "2vw",
    },
    "& .investerText": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.5vw",
        width: "90vw",
      },
      fontSize: "1vw",
      width: "40vw",
    },
    "& .Card": {
      [theme.breakpoints.down(480)]: {
        height: "90vw",
      },
      width: "fit-content",
      height: "20vw",
    },
    "& .leftCardSideBox": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        height: "73vw",
        left: "68vw",
        borderRadius: "2.5vw",
        justifyContent: "flex-start",
        gap: "2.5vw",
      },
      width: "30vw",
      height: "11vw",
      left: "15vw",
      borderRadius: "1vw",
      justifyContent: "center",
      gap: "0.8vw",
    },
    "& .cardMiddleBox": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        height: "80vw",
        borderRadius: "2.5vw",
        justifyContent: "flex-start",
        gap: "2.5vw",
      },
      width: "35vw",
      height: "13vw",
      borderRadius: "1vw",
      justifyContent: "center",
      gap: "0.8vw",
    },
    "& .rightCardSideBox": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        height: "73vw",
        left: "-68vw",
        borderRadius: "2.5vw",
        justifyContent: "flex-start",
        gap: "1.5vw",
      },
      width: "30vw",
      height: "11vw",
      left: "-15vw",
      borderRadius: "1vw",
      justifyContent: "center",
      gap: "0.8vw",
    },
    "& .cardImg": {
      [theme.breakpoints.down(480)]: {
        width: "10vw",
        height: "10vw",
      },
      width: "3vw",
      height: "3vw",
    },
    "& .cardType1": {
      [theme.breakpoints.down(480)]: {
        fontSize: "6vw",
      },
      fontSize: "1.5vw",
    },
    "& .cardType2": {
      [theme.breakpoints.down(480)]: {
        fontSize: "5vw",
        fontWeight: 700,
      },
      fontSize: "1vw",
      fontWeight: 500,
    },
    "& .cardTypeContent": {
      [theme.breakpoints.down(480)]: {
        fontSize: "4vw",
      },
      fontSize: "1vw",
    },
    "& .questionCon": {
      [theme.breakpoints.down(480)]: {
        paddingTop: "2vw",
        paddingBottom: "1vw",
      },
      paddingTop: "8vw",
      paddingBottom: "5vw",
    },
    "& .freqQuestion": {
      fontSize: "36px",
    },
    "& .freqSubHeading": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.8vw",
      },
      fontSize: "1vw",
    },

    "& .questionHeadingContainer": {
      [theme.breakpoints.down(480)]: {
        padding: "1vw",
      },
    },
    "& .contentBox": {
      [theme.breakpoints.down(480)]: {
        padding: "2vw",
        gap: "2.5vw",
      },
      padding: "2vw",
      gap: "1.2vw",
    },
    "& .contentItem": {
      [theme.breakpoints.down(480)]: {
        width: "95vw",
      },
      width: "60vw",
    },
    "& .contentQuestion": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        gap: "0.5vw",
      },
      fontSize: "18px",
      gap: "0.1vw",
    },
    "& .downIcon": {
      [theme.breakpoints.down(480)]: {
        width: "6vw",
      },
      width: "1.2vw",
    },
    "& .optionBox": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        color: "#1E293B",
        borderRadius: "1vw",
      },
      fontSize: "1.1vw",
      color: "#1E293B",
      borderRadius: "0.5vw",
    },
    "& .CardPoints": {
      [theme.breakpoints.down(480)]: {
        gap: "2.5vw",
        paddingBottom: "1.5rem",
      },
      gap: "0.5vw",
    },
    "& .footerContainter": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "row",
      },
    },
    "& .footerCon1": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column",
        borderRadius: "5vw",
      },
      borderRadius: "1.5vw",
    },
    "& .footerBox": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column" as "column",
        alignItems: "flex-start",
        padding: "2vw",
      },
      flexDirection: "column" as "column",
      alignItems: "center",
    },
    "& .footerLogo": {
      [theme.breakpoints.down(480)]: {
        flexDirection: "column",
        height: "fit-content",
      },
      flexDirection: "row",
      height: "12vw",
    },
    "& .footerlogoBox": {
      [theme.breakpoints.down(480)]: {
        width: "95vw",
        padding: "1vw",
        gap: "2vw",
      },
      width: "23vw",
    },
    "& .footerLogoo": {
      [theme.breakpoints.down(480)]: {
        width: "35vw",
      },
      width: "12vw",
    },
    "& .widthImg": {
      [theme.breakpoints.down(480)]: {
        width: "24vw",
      },
      width: "10vw",
    },
    "& .footerInput": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.5vw",
      },
      fontSize: "1vw",
    },
    "& .footerIcons": {
      [theme.breakpoints.down(480)]: {
        width: "30vw",
      },
      padding: "0.5vw",
    },
    "& .linkIcons": {
      [theme.breakpoints.down(480)]: {
        width: "4vw",
      },
      width: "1.5vw",
    },
    "& .logoSideLine": {
      [theme.breakpoints.down(480)]: {
        borderLeft: "0.3vw solid #fff",
        height: "4vw",
      },
      borderLeft: "0.1vw solid #fff",
      height: "1vw",
    },
    "& .midFooter": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.5vw",},fontSize: "1.4vw"},
    "& .midAbout": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.5vw",},fontSize: "1vw",
    },
    "& .rightBox": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.5vw",},fontSize: "1.4vw",
    },
    "& .subscriobe": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3.6vw",},fontSize: "1vw",},
    "& .footBox": {
      [theme.breakpoints.down(480)]: {
        width: "95vw",
        gap: "2vw",
      },
      width: "23vw",
      gap: "0.5vw",
    },
    "& .rightText": {
      [theme.breakpoints.down(480)]: {
        width: "100vw",
        height: "5vw",
        borderRadius: "1vw",
        padding: "3vw",
        fontSize: "3vw",
      },
      width: "70vw",
      height: "1.8vw",
      borderRadius: "0.4vw",
      fontSize: "0.8vw",
      padding: "1.2vw",
    },
    "& .FooterIconImage": {
      [theme.breakpoints.down(480)]: {
        borderRadius: "1vw",
      },
      borderRadius: "0.4vw",
    },
    "& .newsIcon": {
      [theme.breakpoints.down(480)]: {
        width: "5vw",
      },
      width: "1vw",
    },
    "& .copyWriteBox": {
      [theme.breakpoints.down(480)]: {
        width: "95vw",
        gap: "1.5vw",
      },
      width: "75vw",
      gap: "0.8vw",
    },
    "& .copyIcon": {
      [theme.breakpoints.down(480)]: {
        width: "2vw",
        height: "3vw",
      },
      width: "1vw",
      height: "2vw",
    },
    "& .copyText": {
      [theme.breakpoints.down(480)]: {
        fontSize: "2vw",
      },
      fontSize: "1vw",
    },
    "& .footerCon2": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        justifyContent: "center",
        borderBottom: "0.2vw solid #8493BA",
        borderRight: "none",
        paddingBottom: "2vw",
      },
      width: "40vw",
      justifyContent: "flex-start",
    },
    "& .icons": {
      [theme.breakpoints.down(480)]: {
        width: "5vw",
        height: "5vw",
      },
      width: "2vw",
      height: "2vw",
    },
    "& .footerEmail": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        fontWeight: 700,
      },
      fontSize: "1.2vw",
      fontWeight: 600,
    },
    "& .footerImg2": {
      [theme.breakpoints.down(480)]: {
        width: "70vw",
        justifyContent: "center",
      },
      width: "45vw",
      justifyContent: "flex-start",
    },
    "& .aboutBox3": {
      [theme.breakpoints.down(480)]: {
        padding: "2vw",
      },
    },
    "& .invester": {
      [theme.breakpoints.down(480)]: {
        padding: "0vw",
        width: "100%",
        overflow: "hidden",
      },
      padding: "5vw",
    },
    "& .footerContact": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        fontWeight: 700,
      },
      fontSize: "1.2vw",
      fontWeight: 600,
    },
    "& .modalMainContainer": {
      [theme.breakpoints.down(480)]: {
        width: "100%",
        height: "100%",
      },
      width: "100vw",
      height: "fit-content",
    },
    "& .OurImgBox1": {
      [theme.breakpoints.down(480)]: {
        paddingBottom: "0",
        paddingTop: "0",
      },
      paddingBottom: "5vw",
      paddingTop: "0.5vw",
    },
    "& .modalContainer": {
      [theme.breakpoints.down(480)]: {
        width: "90vw",
        height: "fit-content",
        borderRadius: "1vw 1vw 5vw 1vw",
        padding: "2vw 1vw",
        gap: "1vw",
      },
      width: "34vw",
      height: "fit-content",
      borderRadius: "0.4vw",
    },
    "& .modalHeading": {
      [theme.breakpoints.down(480)]: {
        fontSize: "5vw",
      },
      fontSize: "1.4vw",
    },
    "& .modalBodyContainer": {
      [theme.breakpoints.down(480)]: {
        padding: "0 4vw",
      },
      padding: "0 2vw",
    },
    "& .profitText": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
      },
      fontSize: "1vw",
    },
    "& .modalInputLabel": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
      },
      fontSize: "1vw",
    },
    "& .investmentStyle": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        padding: "1.8vw 0 1.8vw 2vw",
        borderRadius: "1vw",
      },
      fontSize: "1vw",
      padding: "0.8vw 0 0.8vw 1vw",
      borderRadius: "0.2vw",
    },
    "& .investmentSelect": {
      [theme.breakpoints.down(480)]: {
        fontSize: "2vw",
        maxWidth: "14vw",
      },
      fontSize: "1vw",
      maxWidth: "8vw",
    },
    "& .investmentAmount": {
      [theme.breakpoints.down(480)]: {
        paddingLeft: "2.5vw",
        width: "45vw",
        fontSize: "2.5vw",
      },
      paddingLeft: "0.8vw",
      width: "22vw",
      fontSize: "1vw",
    },
    "& .modalReturnInput": {
      [theme.breakpoints.down(480)]: {
        padding: "2.4vw 3vw",
        borderRadius: "2vw"
      },
      padding: "1.4vw 2vw",
    },
    "& .button": {
      [theme.breakpoints.down(480)]: {
        width: "20vw",
        fontSize: "3vw",
        padding: "1vw",
        borderRadius: "1vw",
      },
      width: "6vw",
      fontSize: "0.8vw",
      padding: "0.8vw",
      borderRadius: "0.4vw",
    },
    "& .buttonClr": {
      [theme.breakpoints.down(480)]: {
        fontSize: "3vw",
        width: "20vw",
        borderRadius: "1vw",
      },
      padding: "0.8vw",
      fontSize: "0.8vw",
      width: "6vw",
      borderRadius: "0.4vw",
    },
    "& .btnContainer": {
      [theme.breakpoints.down(480)]: {
        gap: "3vw",
        padding: "2vw",
        width: "84vw",
        paddingTop: "3vw"
      },
      gap: "1vw",
      padding: "1.5vw 1.2vw",
    },
    "& .modalHeaderContainer": {
      [theme.breakpoints.down(480)]: {
        padding: ".5 2.5vw 2.5vw 3.5vw",
        borderBottom: 0
      },
      padding: "1vw 1vw 1vw 2vw",
      borderBottom: "0.1vw solid #E2E8F0",
    },
    "& .aboutBox6": {
      [theme.breakpoints.down(480)]: {
        paddingLeft: "5vw",
        borderLeft: "3px solid #143288",
      },
      paddingLeft: "1vw",
    },
    "& .cursorPointer": {
      [theme.breakpoints.down(480)]: {
        width: "3vw",
      },
      width: "2vw",
    }
  }));
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          {this.state.openIntCalculator && (
               <Interactivecalculator id="" navigation={this.props.navigation} handleClose={this.openInteractiveCalculator} i18n={this.props.i18n}></Interactivecalculator>
          )}
          <Box style={{...webStyle.mainContainer}}>
            <Box style={{...webStyle.header, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="header">
              <Box style={webStyle.MenuBox}>
                <img src={logo} className="headerLogo" />
              </Box>
              <Box style={webStyle.linkBox} className="linkBox">
              <a href="#C1" style={webStyle.link}>
                <Typography
                  data-test-id="home"
                  className="link"
                  style={this.handleCondition(
                    this.state.activeLink === "home",
                    webStyle.activeLink,
                    webStyle.link
                  )}
                  onClick={() => this.handleActiveLink("home")}
                >
                  {this.props.i18n.t('home')}
                </Typography>
                </a>
                <a href="#C2" style={webStyle.link}>
                <Typography
                  data-test-id="about"
                  className="link"
                  style={this.handleCondition(
                    this.state.activeLink === "about",
                    webStyle.activeLink,
                    webStyle.link
                  )}
                  onClick={() => this.handleActiveLink("about")}
                >
                  {this.props.i18n.t('about')}
                </Typography>
                </a>
                <a href="#C3" style={webStyle.link}>
                <Typography
                  data-test-id="faqs"
                  className="link"
                  style={this.handleCondition(
                    this.state.activeLink === "faqs",
                    webStyle.activeLink,
                    webStyle.link
                  )}
                  onClick={() => this.handleActiveLink("faqs")}
                >
                  {this.props.i18n.t('FAQ')}
                </Typography>
                 </a>
              </Box>
              <Box 
                style={{...webStyle.buttonBox,
                 direction: this.handleCondition(this.props.i18n.language === 'ar' , 'ltr' , 'ltr')}} className="buttonBox">
                 
                <LanguageSwitch
                  isLandingPage={true}
                  lang={this.state.currentLanguage}
                  handleLanguageChange={this.handleLanguageChange}
                />
                <button
                  className="signinButton"
                  data-test-id="signin"
                  style={webStyle.signinButton}
                  onClick={() => this.handleNavigate("EmailAccountLoginBlock")}
                >
                  {this.props.i18n.t('signIn')}
                </button>
                <button
                  className="signupButton"
                  data-test-id="signup"
                  style={webStyle.signupButton}
                  onClick={() =>
                    this.handleNavigate("EmailAccountRegistration")
                  }
                >
                  {this.props.i18n.t('signUp')}
                </button>
              </Box>
            </Box>
            <Box style={webStyle.footerImg}>
              <Box style={{...webStyle.container1, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="container1" id="C1">
                <Box style={webStyle.leftBox} className="leftBox">
                  <Box style={{...webStyle.leftInnerBox, left: this.handleCondition(this.props.i18n.language === 'ar' ,
                            "4vw" ,
                             "-3.8vw"
                             ),}} className="leftInnerBox">
                    <Box style={webStyle.heading} className="heading">
                      <Typography
                        style={webStyle.headText}
                        className="headText"
                      >
                        {this.props.i18n.t('wealth')}
                      </Typography>
                      <Typography
                        style={webStyle.headText}
                        className="headText"
                      >
                        {this.props.i18n.t('management')}
                      </Typography>
                      <Typography
                        style={webStyle.headText}
                        className="headText"
                      >
                        {this.props.i18n.t('services')}
                      </Typography>
                    </Box>
                    <Typography
                      style={webStyle.subHeading}
                      className="subHeading"
                    >
                        {this.props.i18n.t('Sustainable Wealth Growth.')}
                    </Typography>
                    <Button
                      style={webStyle.calculateBtn}
                      className="calculateBtn"
                      onClick={this.openInteractiveCalculator}
                    >
                        {this.props.i18n.t('calMyReturn')}
                    </Button>
                  </Box>
                </Box>
                <img src={mobileImg} className="mobileImg" />
              </Box>
              <Box style={webStyle.aboutContainer} className="aboutContainer" id="C2">
                <Box className="aboutBox">
                  <Box style={webStyle.aboutBox2} className="aboutBox2">
                    <Typography
                      style={webStyle.aboutType}
                      className="aboutType"
                    >
                      {this.props.i18n.t('aboutUs')}
                    </Typography>
                    <Box style={webStyle.aboutBox3} className="aboutBox3">
                      <Box style={webStyle.aboutBox4} className="aboutBox4">
                        <Box
                        style={{...webStyle.aboutBox6,
                        borderLeft: this.handleCondition(this.props.i18n.language === 'ar' , "none" , "4px solid #143288"),
                        paddingRight: this.handleCondition(this.props.i18n.language === 'ar', "1vw" , ""),
                        borderRight: this.handleCondition(this.props.i18n.language === 'ar' , "4px solid #143288", "none")}}
                        className="aboutBox6">
                          <Typography
                            style={webStyle.aboutExp}
                            className="aboutExp"
                          >
                            {this.props.i18n.t('Experience')}
                          </Typography>
                          <Typography
                            style={webStyle.aboutExpHead}
                            className="aboutExpHead"
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: this.state.landingPageData?.experience_description,
                              }}
                            />
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.aboutBox4}>
                        <Box 
                        style={{
                          ...webStyle.aboutBox6,
                           borderLeft: this.handleCondition(this.props.i18n.language === 'ar' ,
                            "none" ,
                             "4px solid #143288"
                             ),
                            paddingRight: this.handleCondition(this.props.i18n.language === 'ar', "1vw" , ""),
                            borderRight: this.handleCondition(this.props.i18n.language === 'ar' ,
                             "4px solid #143288",
                              "none")}}
                               className="aboutBox6">
                          <Typography style={webStyle.aboutExp} className="aboutExp">{this.props.i18n.t('yourGoalOurWorks')}</Typography>
                          <Typography style={webStyle.aboutExpHead} className="aboutExpHead">
                            <div dangerouslySetInnerHTML={{
                                __html: this.state.landingPageData?.goal_description,
                              }}
                            /></Typography>
                        </Box>
                      </Box>
                      <Box style={webStyle.aboutBox4}>
                        <Box
                         style={{...webStyle.aboutBox6, borderLeft: this.handleCondition(this.props.i18n.language === 'ar' ,
                          "none" , "4px solid #143288"),
                          paddingRight: this.handleCondition(this.props.i18n.language === 'ar', "1vw" , ""),
                        borderRight:
                         this.handleCondition(this.props.i18n.language === 'ar' ,
                          "4px solid #143288", "none")}} className="aboutBox6">
                          <Typography
                            style={webStyle.aboutExp} className="aboutExp"
                          > {this.props.i18n.t('responsibleInvest')}
                          </Typography>
                          <Typography style={webStyle.aboutExpHead}
                            className="aboutExpHead" >
                            <div
                              dangerouslySetInnerHTML={{__html: this.state.landingPageData?.investment_description}}/></Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box style={{...webStyle.aboutCompany, alignItems: this.handleCondition(this.props.i18n.language === 'ar' , 'flex-start' , 'flex-end')}} className="aboutCompany">
                    <Box
                      style={{...webStyle.aboutCompanyBox, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'ltr' , '')}}
                      className="aboutCompanyBox"
                    >
                      <Typography
                        style={webStyle.aboutComType}
                        className="aboutComType"
                      >
                        {this.props.i18n.t('Companies')}
                      </Typography>
                      <Box style={{...webStyle.dflex, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} >
                        <Typography
                          style={webStyle.plusType}
                          className="plusType"
                        >
                          +
                        </Typography>
                        <Typography
                          style={webStyle.twoType}
                          className="twoType"
                        >
                          {this.state.landingPageData?.companies}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{...webStyle.flexColend, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr'), alignItems: this.handleCondition(this.props.i18n.language === 'ar' , 'flex-start' , 'flex-end')}} className="flexColend">
                      <Typography style={webStyle.aboutComType} className="aboutComType" >
                        {this.props.i18n.t('CapitalInvest')}
                      </Typography>
                      <Box style={webStyle.dflex}>
                        <Typography style={webStyle.plusType} className="plusType" >
                          +
                        </Typography>
                        <Typography
                          style={webStyle.twoType} className="twoType">
                          {this.state.landingPageData?.capital_investment}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{...webStyle.flexColend, alignItems: this.handleCondition(this.props.i18n.language === 'ar' , 'flex-start' , 'flex-end'), direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} 
                    className="flexColend">
                      <Typography style={webStyle.aboutComType} className="aboutComType">{this.props.i18n.t('Experience')}</Typography>
                      <Box style={webStyle.dflex}> <Typography style={webStyle.plusType} className="plusType">+</Typography>
                        <Typography style={webStyle.twoType} className="twoType">
                          {this.state.landingPageData?.experience}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box style={webStyle.OurPort} className="OurPort">
                  <Box style={{...webStyle.OurPortBox,  direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="OurPortBox">
                    <Typography
                      style={webStyle.OurPortType}
                      className="OurPortType"
                    >
                        {this.props.i18n.t('ourPortfolio')}
                    </Typography>
                    <Typography
                      className="OurPortText"
                      style={webStyle.OurPortText}
                      dangerouslySetInnerHTML={{
                        __html: this.state.landingPageData?.portfolio_description,
                      }}
                    />
                  </Box>
                </Box>
                <Box style={webStyle.Our2} className="Our2">
                  <Box style={webStyle.OurImgBox} className="OurImgBox">
                    <img
                      src={leftIcon}
                      data-test-id="leftIcon"
                      style={webStyle.leftImageStyle}
                      onClick={() => this.handlePortfolioClick("prev")}
                    />
                  </Box>
                  <Box
                    style={{
                      backgroundImage: `url(${
                        this.state.landingPageData?.portfolio_images?.data[
                          this.state.activeProtfolios.first
                        ]?.attributes?.image?.url
                      })`,
                      ...webStyle.imageone,
                    }}
                    className="imageone"
                  >
                    <Box style={webStyle.imageoneBox} className="imagetwoBox">
                      <Box
                        style={{...webStyle.contentBoxofImage,direction: this.handleCondition(this.props.i18n.language === 'ar', 'rtl', 'ltr')}}
                        className="contentBoxofImage2"
                      >
                        <Typography
                          style={webStyle.contentTypeone}
                          className="contentTypeone"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.first
                            ]?.attributes?.image_title
                          }
                        </Typography>
                        <Typography
                          style={webStyle.contentTypeTwo}
                          className="contentTypeTwo"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.first
                            ]?.attributes?.image_description
                          }
                        </Typography>
                        <Box
                          style={webStyle.contentButton}
                          className="contentButton"
                        >
                          <button
                            style={webStyle.contentButtonStyle}
                            className="contentButtonStyle"
                          >
                            <Link
                              href={`${
                                this.state.landingPageData?.portfolio_images
                                  ?.data[this.state.activeProtfolios.first]
                                  ?.attributes?.explore_link
                              }`}
                              style={webStyle.exploreMore}
                            >    
                              {this.props.i18n.t('exploreMore')}
                            </Link>
                            {this.handleCondition(this.props.i18n.language === 'ar' , <ArrowBack
                              style={webStyle.arrowStyle} className="arrowStyle"
                            /> ,
                                 <ArrowForward style={webStyle.arrowStyle} className="arrowStyle" />)}
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <div>
                  <Box
                    style={{
                      backgroundImage: `url(${
                        this.state.landingPageData?.portfolio_images?.data[
                          this.state.activeProtfolios.second
                        ]?.attributes?.image?.url
                      })`,
                      ...webStyle.imagetwo,
                    }}
                    // sec
                    className="imagetwo"
                  >
                    <Box style={webStyle.imagetwoBox} className="imagetwoBox">
                      <Box
                        style={{...webStyle.contentBoxofImage2,direction: this.handleCondition(this.props.i18n.language === 'ar', 'rtl', 'ltr')}}
                        className="contentBoxofImage2"
                      >
                        <Typography
                          style={webStyle.contentTypeone}
                          className="contentTypeone"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.second
                            ]?.attributes?.image_title
                          }
                        </Typography>
                        <Typography
                          style={webStyle.contentTypeTwo}
                          className="contentTypeTwo"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.second
                            ]?.attributes?.image_description
                          }
                        </Typography>
                        <Box
                          style={webStyle.contentButton}
                          className="contentButton"
                        >
                          <button
                            style={webStyle.contentButtonStyle}
                            className="contentButtonStyle"
                          >
                            <Link
                              href={`${
                                this.state.landingPageData?.portfolio_images
                                  ?.data[this.state.activeProtfolios.second]
                                  ?.attributes?.explore_link
                              }`}
                              style={webStyle.exploreMore}
                            >
                              {this.props.i18n.t('exploreMore')}
                            </Link>
                            {this.handleCondition(this.props.i18n.language === 'ar' , <ArrowBack
                              style={webStyle.arrowStyle}                              className="arrowStyle"                            /> , <ArrowForward
                            style={webStyle.arrowStyle}                            className="arrowStyle"                            />)}
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  </div>
                 
                  <Box
                    style={{
                      backgroundImage: `url(${
                        this.state.landingPageData?.portfolio_images?.data[
                          this.state.activeProtfolios.third
                        ]?.attributes?.image?.url
                      })`,
                      ...webStyle.imagethree,
                    }}
                    className="imagethree"
                  >
                    <Box style={webStyle.imageoneBox} className="imagetwoBox">
                      <Box
                        style={{...webStyle.contentBoxofImage,direction: this.handleCondition(this.props.i18n.language === 'ar', 'rtl', 'ltr')}}
                        className="contentBoxofImage2"
                      >
                        <Typography
                          style={webStyle.contentTypeone}
                          className="contentTypeone"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.third
                            ]?.attributes?.image_title
                          }
                        </Typography>
                        <Typography
                          style={webStyle.contentTypeTwo}
                          className="contentTypeTwo"
                        >
                          {
                            this.state.landingPageData?.portfolio_images?.data[
                              this.state.activeProtfolios.third
                            ]?.attributes?.image_description
                          }
                        </Typography>
                        <Box
                          style={webStyle.contentButton}
                          className="contentButton"
                        >
                          <button
                            style={webStyle.contentButtonStyle}
                            className="contentButtonStyle"
                          >
                            <Link
                              href={`${
                                this.state.landingPageData?.portfolio_images
                                  ?.data[this.state.activeProtfolios.third]
                                  ?.attributes?.explore_link
                              }`}
                              style={webStyle.exploreMore}
                            >
                              {this.props.i18n.t('exploreMore')}
                            </Link>
                            {this.handleCondition(this.props.i18n.language === 'ar' , <ArrowBack
                              style={webStyle.arrowStyle}
                              className="arrowStyle"
                            /> , <ArrowForward
                            style={webStyle.arrowStyle}
                            className="arrowStyle"
                            />)}
                          </button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box style={webStyle.OurImgg} className="OurImgg">
                    <img
                      src={rightIcon}
                      data-test-id="rightIcon"
                      style={webStyle.leftImageStyle}
                      onClick={() => this.handlePortfolioClick("next")}
                    />
                  </Box>
                </Box>
                <Box style={webStyle.OurImgBox1} className="OurImgBox1">
                  <Box style={webStyle.OurImgBox2} className="OurImgBox2">
                    {this.state.landingPageData?.portfolio_images?.data?.map(
                      (obj: any, index: any) => (
                        <Box
                          data-test-id="handleIndex"
                          style={webStyle.circleWeb}
                          onClick={() => this.handleIndex(index)}
                          className={
                            this.state.activeProtfolios.second === index
                              ? "activeCircle"
                              : "circlePortfolio"
                          }
                        />
                      )
                    )}
                  </Box>
                </Box>
              </Box>
              <Box style={webStyle.invester} className="invester">
                <Box style={{...webStyle.dCenter,direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="dCenter">
                  <Box style={webStyle.investerBox}>
                    <Typography
                      style={webStyle.investHeading}
                      className="investHeading"
                    >
                      {this.props.i18n.t('happyIvest')}
                    </Typography>
                    <Typography
                      className="investerText"
                      style={webStyle.investerText}
                      dangerouslySetInnerHTML={{
                        __html: this.state.landingPageData?.happy_customer_description,
                      }}
                    />
                  </Box>
                </Box>
                <Box style={webStyle.Card} className="Card">
                  <Box
                    style={webStyle.leftCardSideBox}
                    className="leftCardSideBox"
                  >
                    <img src={this.handleRenderJsx("first", "image")} style={webStyle.cardImg} className="cardImg" />
                    <Typography
                      style={webStyle.cardType1}
                      className="cardType1"
                    >{this.handleRenderJsx("first", "name")}</Typography>
                    <Typography
                      style={webStyle.cardType2}
                      className="cardType2"
                    >{this.handleRenderJsx("first", "designation")}</Typography>
                    <Typography
                      className="cardTypeContent"
                      style={webStyle.cardTypeContent}
                      dangerouslySetInnerHTML={{__html: this.handleRenderJsx("first", "description")}}
                    />
                  </Box>
                  <Box style={webStyle.cardMiddleBox} className="cardMiddleBox">
                    <img
                      src={this.handleRenderJsx("second", "image")}
                      style={webStyle.cardImg}                   className="cardImg"
                    />
                    <Typography
                      style={webStyle.cardType1}
                      className="cardType1"
                    >{this.handleRenderJsx("second", "name")}</Typography>
                    <Typography
                      style={webStyle.cardType2}
                      className="cardType2"
                    > {this.handleRenderJsx("second", "designation")}
                    </Typography>
                    <Typography
                      className="cardTypeContent"
                      style={webStyle.cardTypeContent}
                      dangerouslySetInnerHTML={{__html: this.handleRenderJsx("second", "description")}}
                    />
                  </Box>
                  <Box
                    style={webStyle.rightCardSideBox}
                    className="rightCardSideBox"
                  >
                    <img
                      src={this.handleRenderJsx("third", "image")}
                      style={webStyle.cardImg}
                      className="cardImg"
                    />
                    <Typography
                      style={webStyle.cardType1}
                      className="cardType1"
                    >
                      {this.handleRenderJsx("third", "name")}
                    </Typography>
                    <Typography
                      style={webStyle.cardType2}
                      className="cardType2"
                    >{this.handleRenderJsx("third", "designation")}</Typography>
                    <Typography
                      className="cardTypeContent"
                      style={webStyle.cardTypeContent}
                      dangerouslySetInnerHTML={{__html: this.handleRenderJsx("third", "description")}}
                    />
                  </Box>
                </Box>
                <Box style={webStyle.CardPoints} className="CardPoints">
                  {this.state.landingPageData?.happy_investors?.data.map(
                    (obj: any, index: any) => (
                      <Box
                        data-test-id="paginate"
                        key={index}
                        className=""
                        style={{
                          ...webStyle.CardPoint1,
                          width:
                            this.state.activeHappyInvestors.second === index
                              ? "1.4vw"
                              : "1vw",
                          height:
                            this.state.activeHappyInvestors.second === index
                              ? "1.4vw"
                              : "1vw",
                        }}
                        onClick={() => this.handleClick(index)}
                      />
                    )
                  )}
                </Box>
                <Box
                  style={webStyle.contentButton}
                  className="contentButtonViewMore"
                >
                  <button
                    style={webStyle.contentButtonStyle}
                    className="contentButtonStyle"
                    onClick={this.customerReviews}
                  >
                      {this.props.i18n.t('viewMore')}
                    {this.handleCondition(this.props.i18n.language === 'ar', <ArrowBack
                      style={webStyle.arrowStyle} className="arrowStyle"
                    />,
                      <ArrowForward style={webStyle.arrowStyle} className="arrowStyle" />)}
                  </button>
                </Box>
              </Box>
              <Box style={{...webStyle.questionCon, direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="questionCon" id="C3">
                <Box
                  style={webStyle.questionHeadingContainer}
                  className="questionHeadingContainer"
                >
                  <Typography
                    style={webStyle.freqQuestion}
                    className="freqQuestion"
                  >
                      {this.props.i18n.t('askQuestion')}
                  </Typography>
                  <div
                    className="freqSubHeading"
                    style={webStyle.freqSubHeading}
                    dangerouslySetInnerHTML={{
                      __html: this.state.landingPageData?.investor_description,
                    }}
                  />
                </Box>
                <Box style={webStyle.contentBox} className="contentBox">
                  {this.state.freqAskQuestion?.map((obj: any, index: any) => (
                    <Box
                      key={index}
                      style={webStyle.contentItem}
                      className="contentItem"
                    >
                      <Box style={webStyle.contentHeading}>
                        <Typography
                          style={webStyle.contentQuestion}
                          className="contentQuestion"
                        >
                          {obj.attributes.question}
                        </Typography>
                        <img
                          className="downIcon"
                          style={webStyle.downIcon}
                          data-test-id="downIcon"
                          src={this.state.activeAnswerIndex === index ? upIcon : downIcon}
                          onClick={() => this.setActiveAnswerIndex(index)}
                        />
                      </Box>
                      {this.handleCondition(
                        this.state.activeAnswerIndex === index,
                        <Box
                          className="optionBox"
                          style={webStyle.optionBox}
                          dangerouslySetInnerHTML={{
                            __html: obj.attributes.description,
                          }}
                        />,
                        ""
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                style={webStyle.footerContainter}
                className="footerContainter"
              >
                <Box style={webStyle.footerCon}>
                  <Box style={{...webStyle.footerCon1,direction: this.handleCondition(this.props.i18n.language === 'ar', 'rtl', 'ltr')}} className="footerCon1">
                    <Box style={{...webStyle.footerCon2, borderRight: this.handleCondition(this.props.i18n.language === 'ar' , "none" , "0.2vw solid #8493BA"),
                        borderLeft: this.handleCondition(this.props.i18n.language === 'ar' , "0.2vw solid #8493BA", "none")}} className="footerCon2">
                      <Box style={webStyle.footerImgBox}>
                        <img src={mailIcon} className="icons" />
                      </Box>
                      <Typography
                        style={webStyle.footerEmail}
                        className="footerEmail"
                      >
                        {this.state.landingPageData?.gmail_url}
                      </Typography>
                    </Box>
                    <Box style={webStyle.footerImg2} className="footerImg2">
                      <Box
                        style={webStyle.footerImg2Box}
                        className="footerImg2Box"
                      >
                        <img src={callIcon} className="icons" />
                      </Box>
                      <Typography
                        style={webStyle.footerContact}
                        className="footerContact"
                      >
                        {this.state.landingPageData?.contact_no}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box style={{...webStyle.footerBox,direction: this.handleCondition(this.props.i18n.language === 'ar' , 'rtl' , 'ltr')}} className="footerBox">
                    <Box style={webStyle.footerLogo} className="footerLogo">
                      <Box
                        style={webStyle.footerlogoBox}
                        className="footerlogoBox"
                      >
                        <Box
                          style={webStyle.footerLogoo}
                          className="footerLogoo"
                        >
                          <img src={whitebg} className="widthImg" />
                        </Box>
                        <Typography
                          style={webStyle.footerInput}
                          className="footerInput"
                        >
                         {this.state.landingPageData?.address}
                        </Typography>
                        <Box
                          style={webStyle.footerIcons}
                          className="footerIcons"
                        >
                          <Link
                            href={`${this.state.landingPageData?.facebook_url}`}
                          >
                            <img src={facebook} className="linkIcons" />
                          </Link>
                          <div
                            style={webStyle.logoSideLine}
                            className="logoSideLine"
                          />
                          <Link
                            href={`${this.state.landingPageData?.insta_url}`}
                          >
                            <img src={instagram} className="linkIcons" />
                          </Link>
                          <div
                            style={webStyle.logoSideLine}
                            className="logoSideLine"
                          />
                          <Link
                            href={`${this.state.landingPageData?.twitter_url}`}
                          >
                            <img src={twitter} className="linkIcons" />
                          </Link>
                          <div
                            style={webStyle.logoSideLine}
                            className="logoSideLine"
                          />
                          <Link
                            href={`${this.state.landingPageData?.youtube_url}`}
                          >
                            <img src={youtube} className="linkIcons" />
                          </Link>
                        </Box>
                      </Box>
                      <Box
                        style={webStyle.footerlogoBox1}
                        className="footerlogoBox"
                      >
                        <Typography
                          style={webStyle.midFooter}
                          className="midFooter"
                        >
                          {this.props.i18n.t('quickLink')}
                        </Typography>
                        <Typography
                          style={webStyle.midAbout}
                          className="midAbout"
                        >
                          <a href="#C2" style={webStyle.midAbout}>{this.props.i18n.t('about')}</a>
                        </Typography>
                        <Box
                        data-test-id="handleNavigateToTC"
                         style={{cursor: "pointer"}}
                         onClick={this.handleNavigateToTC}>
                          <Typography
                          style={webStyle.midAbout}
                          className="midAbout"
                        >
                          {this.props.i18n.t('T&C')}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        style={webStyle.footerlogoBox}
                        className="footerlogoBox"
                      >
                        <Typography
                          style={webStyle.rightBox}
                          className="rightBox"
                        >
                          {this.props.i18n.t('newsLetter')}
                        </Typography>
                        <Typography
                          style={webStyle.subscriobe}
                          className="subscriobe"
                        >
                          {this.props.i18n.t('subscribeOur')}
                        </Typography>
                        <Box style={webStyle.footBox} className="footBox">
                          <input
                            placeholder={this.props.i18n.t('yourEmailAddress')}
                            style={webStyle.rightText}
                            className="rightText"
                            data-test-id = "newsInput"
                            value={this.state.newsLetterEmail}
                            onChange={(event) =>
                              this.setState({ newsLetterEmail: event.target.value })
                            }
                          />
                          <Box
                            style={webStyle.FooterIconImage}
                            className="FooterIconImage" 
                            data-test-id = "handleNewsApi"
                            onClick={this.handleNewsLetterApi}
                          >
                            <img src={this.handleCondition(this.props.i18n.language === 'ar' , arIcon , rightIcon)} className="newsIcon" />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box style={webStyle.copyWriteBox} className="copyWriteBox">
                      <img
                        src={copywrite}
                        style={webStyle.copyIcon}
                        className="copyIcon"
                      />
                      <Typography
                        style={webStyle.copyText}
                        className="copyText"
                      > 
                        {this.props.i18n.t('copyRight')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </this.MainWrapper>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    width: "100%",
    height: "100%",
  },
  footBox: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "0.8vw",
  },
  FooterIconImage: {
    display: "inline-flex",
    padding: "0.7vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "1vw",
    background: "#1B4FE4",
  },
  copyText: {
    color: "#FFF",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  copyWriteBox: {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    background: "#143288",
    padding: "1vw 0 1vw 0",
    borderTop: "0.1vw solid white",
    marginTop: "3.2vw",
  },
  copyIcon: {},
  header: {
    width: "calc(100vw - 3.65vw)",
    padding: "0.25vw 1.65vw 0.25vw 1.8vw",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    position: "fixed" as "fixed",
    top: 0,
    left: 0,
    zIndex: 990,
  },
  MenuBox: { display: "flex", alignItems: "center", gap: "2vw" },
  MenuIcons: { width: "4vw", height: "4vw" },
  logoSideLine: {},
  linkBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.5vw",
  },
  link: {
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    fontSize: "1vw",
    color: "#334155",
    cursor: "pointer",
    borderBottom: "0.2vw solid transparent",
    textTransform: "uppercase" as "uppercase",
    textDecoration:"none"
  },
  activeLink: {
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
    fontSize: "1vw",
    color: "#1B4FE4",
    cursor: "pointer",
    textTransform: "uppercase" as "uppercase",
    borderBottom: "0.2vw solid #1B4FE4",
  },
  buttonBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  signinButton: {
    border: "0.01vw solid #1B4FE4",
    backgroundColor: "white",
    color: "#1B4FE4",
    fontFamily: "DIN Next LT Arabic Bold",
    textAlign: "center" as "center",
  },
  signupButton: {
    border: "0.01vw solid #1B4FE4",
    backgroundColor: "#1B4FE4",
    color: "white",
    fontFamily: "DIN Next LT Arabic Bold",
    textAlign: "center" as "center",
  },
  container1: {
    width: "100vw",
    height: "fit-content",
    padding: "100px 0",
    backgroundColor: "#143288",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10vw",
    position: "relative" as "relative",
    top: "4.5vw",
  },
  leftBox: {
    paddingBottom: "3vw",
  },
  leftInnerBox: {
    backgroundColor: "rgba(132, 147, 186, 1)",
    position: "relative" as "relative",
    color: "white",
    display: "flex",
    gap: "1.5vw",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  heading: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  headText: {
    textTransform: "uppercase" as "uppercase",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  subHeading: {
    fontFamily: "DIN Next LT Arabic Regular",
    fontWeight: 500,
    textTransform: "capitalize" as "capitalize",
  },
  calculateBtn: {
    color: "white",
    backgroundColor: "#1B4FE4",
    fontFamily: "DIN Next LT Arabic Bold",
    fontWeight: 700,
  },
  questionCon: {},
  questionHeadingContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  freqQuestion: {
    color: "#292524",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    fontSize: "36px",
  },
  freqSubHeading: {
    color: "#475569",
    textAlign: "center" as "center",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  subQuestion: {
    display: "inline-flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "20px",
  },
  contentBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column" as "column",
  },
  contentItem: {},
  contentHeading: {
    display: "flex",
    alignItems: "center",
    gap: "1vw",
    padding: "1vw",
    justifyContent: "space-between",
    borderRadius: "0.8vw",
    background: "#FFF",
    boxShadow:
      " 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  contentQuestion: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  downIcon: {},
  iconStyle: { width: "3.2%", height: "3.2%", flexShrink: "0" },
  optionBox: {
    alignItems: "center",
    padding: "0 1.1vw",
    border: "0.01vw solid #F1F5F9",
    background: "#F1F5F9",
    boxShadow: "0px 2px 8px 0px #00000014",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  footerContainter: { width: "100%", height: "fit-content" },
  footerCon: {
    backgroundImage: "linear-gradient(to bottom, #FFF 40%, #143288 40%)",
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2vw",
  },
  footerCon1: {
    backgroundColor: "white",
    padding: "4vw 3vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "2vw",
    width: "70vw",
    boxShadow:
      "0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  footerCon2: {
    display: "flex",
    alignItems: "center",
    gap: "2vw",
    border: "",
  },
  footerImgBox: {
    backgroundColor: "#143288",
    padding: "1.8vw",
    borderRadius: "50vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerImg: {
    width: "100%",
    height: "100%",
    zIndex: 8,
    position: "relative" as "relative",
  },
  footerEmail: {
    fontFamily: "Josefin Sans",
  },
  footerImg2: {
    display: "flex",
    alignItems: "center",
    gap: "2vw",
  },
  footerImg2Box: {
    backgroundColor: "#143288",
    padding: "1.4vw",
    borderRadius: "50vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  footerContact: {
    fontFamily: "Josefin Sans",
  },
  footerBox: {
    display: "flex",
    weight: "100vw",
    justifyContent: "flex-start",
    background: "#143288",
  },
  footerLogo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "75vw",
  },
  footerlogoBox: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between",
  },
  footerlogoBox1: {
    height: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-start",
    gap: "1.1vw"
  },
  footerLogoo: {
    overflow: "hidden",
    display: "flex",
  },
  footerInput: {
    color: "#FFF",
    fontWeight: 400,
    padding: "0.5vw",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  footerIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  midFooter: {
    color: "#FFF",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    padding: 0,
  },
  midAbout: {
    color: "#FFF",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    textDecoration:"none"
  },
  rightBox: {
    color: "#FFF",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  subscriobe: {
    color: "#FFF",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  rightText: {
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic Regular",
    backgroundColor: "white",
    textDecoration: "none",
    border: "none",
  },
  aboutContainer: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    zIndex: 999,
  },
  aboutBox2: {
    flexDirection: "column" as "column",
    gap: "5.2vw",
    margin: "1vw",
  },
  aboutType: {
    color: "#1E293B",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  aboutBox3: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "1.2vw",
  },
  aboutBox4: {
    display: "flex",
    alignItems: "center",
    gap: "1.2vw",
  },
  aboutBox6: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "1vw",
    backgroungColor: "#FFFFFF",
  },
  aboutExp: {
    color: "#1E293B",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    textTransform: "uppercase" as "uppercase",
  },
  aboutExpHead: {
    color: "#64748B",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    alignSelf: "stretch",
  },
  aboutBoxx1: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  aboutBoxxTypo1: {
    color: "#64748B",
    fontSize: "1vw",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    alignSelf: "stretch",
  },
  boxAbout: {
    display: "flex",
    width: "4px",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "flex-start",
    flexShrink: "0",
    alignSelf: "stretch",
    borderRadius: "8px",
    background: "#143288",
  },
  aboutCompany: {
    display: "inline-flex",
    flexDirection: "column" as "column",
  },
  aboutCompanyBox: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  aboutComType: {
    color: "#475569",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  dflex: { display: "flex" },
  plusType: {
    color: "#1740B2",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  twoType: {
    color: "#000",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  flexColend: {
    display: "flex",
    flexDirection: "column" as "column",
  },
  capitalType: {
    color: "#475569",
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  OurPort: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  OurPortBox: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "0.5vw",
  },
  OurPortType: {
    color: "#1E293B",
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic Bold",
    textAlign: "center" as "center",
  },
  OurPortText: {
    color: "#64748B",
    textAlign: "center" as "center",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "4vw",
  },
  Our2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1.2vw",
  },
  OurImgBox: {
    justifyContent: "center",
    alignItems: "center",
    gap: "2vw",
    padding: "0.6vw",
    height: "2vw",
    background: "#F8FAFC",
    borderRadius:"50%"
  },
  leftImageStyle: {
    width: "2vw",
  },
  imageone: {
    // width: "20.7vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    // minHeight: "31vw",
    backgroundPosition: "center",
    borderRadius: "1vw 0 0 1vw",
  },
  imageoneBox: {
    height: "30vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  imagetwo: {
    backgroundRepeat: "no-repeat",
    backgroundSize:"cover",
    backgroundPosition: "center",
  },
  imagetwoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  imagethree: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "0 1vw 1vw 0",
    backgroundPosition: "center",
  },
  contentBoxofImage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as "column",
    width: "18vw",
    height: "fit-content",
    padding: "1vw",
    gap: "1vw",
  },
  contentBoxofImage2: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column" as "column",
    height: "fit-content",
    padding: "1vw",
    gap: "1.5vw",
    paddingBottom: "1vw",
  },
  contentTypeone: {
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#F1F5F9",
    textTransform: "uppercase" as "uppercase",
  },
  contentTypeTwo: {
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#FFFFFF",
  },
  contentButton: {
    background: "#FFFFFF",
    padding: "0.4vw 0.5vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width:"fit-content"
  },
  contentButtonStyle: {
    color: "#292524",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    // display: "flex",
    // alignItems: "start",
    // justifyContent: "center",
    background: "transparent",
    border: "none",
    gap: "1vw",
  },
  exploreMore: { color: "#292524", textDecoration: "none" },
  arrowStyle: { color: "#D97706" },
  OurImgg: {
    justifyContent: "center",
    alignItems: "center",
    gap: "2vw",
    padding: "0.8vw",
    borderRadius: "3vw",
    background: "#1B4FE4",
  },
  OurImgBox1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  OurImgBox2: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.8vw",
  },
  circleWeb: {
    background: "#E2E8F0",
    borderRadius: "100%",
  },
  invester: {
    background: "#143288",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "5vw",
  },
  investerBox: {
    display: "inline-flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    gap: "1vw",
  },
  investHeading: {
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#fff",
    textAlign: "center" as "center",
  },
  investerText: {
    color: "#FFF",
    textAlign: "center" as "center",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
  },
  Card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "2vw",
  },
  cardMiddleBox: {
    background: "#FFF",
    padding: "3.5vw 2vw",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    zIndex: "2",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  leftCardSideBox: {
    background: "#FFF",
    padding: "3vw 1vw",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    zIndex: "1",
    position: "relative" as "relative",
    overflow: "hidden",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  cardImg: { borderRadius: "50vw" },
  rightCardSideBox: {
    background: "#FFF",
    padding: "3vw 1vw",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    zIndex: "1",
    overflow: "hidden",
    position: "relative" as "relative",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  CardBox: {
    width: "40%",
    height: "60%",
    background: "#FFF",
    padding: "50px 12px 50px 12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "12px",
    zIndex: "1",
    position: "relative" as "relative",
    left: "50",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  CardBoxImg: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  cardType1: {
    color: "#292524",
    textAlign: "center" as "center",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
  },
  cardType2: {
    color: "#1E293B",
    textAlign: "center" as "center",
    fontFamily: "DIN Next LT Arabic Regular",
  },
  cardTypeContent: {
    color: "#64748B",
    textAlign: "center" as "center",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
  },
  cardIMG: {
    width: "70%",
    height: "75%",
    background: "#FFF",
    padding: "60px 12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    borderRadius: "12px",
    zIndex: "2",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  cardIMGbox: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  card2: {
    color: "#292524",
    textAlign: "center" as "center",
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    lineHeight: "44px",
    letterSpacing: "-.36px",
  },
  cardtype2: {
    color: "#1E293B",
    textAlign: "center" as "center",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic regular",
    lineHeight: "44px",
  },
  cardtypetext2: {
    color: "#64748B",
    textAlign: "center" as "center",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    lineHeight: "24px",
  },
  rightCard: {
    width: "40%",
    height: "60%",
    background: "#FFF",
    padding: "50px 12px 50px 12px",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    zIndex: "1",
    position: "relative" as "relative",
    right: "100",
    borderRadius: "12px",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  rightCardBox: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
  },
  rightCardHeading: {
    color: "#292524",
    textAlign: "center" as "center",
    fontSize: "36px",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    lineHeight: "44px",
    letterSpacing: "-.36px",
  },
  rightCardSubHeading: {
    color: "#1E293B",
    textAlign: "center" as "center",
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "DIN Next LT Arabic Regular",
    lineHeight: "44px",
  },
  rightCardType: {
    color: "#64748B",
    textAlign: "center" as "center",
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "DIN Next LT Arabic Regular",
    lineHeight: "24px",
  },
  CardPoints: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  CardPointsBox: {
    display: "inline-flex",
    paddingTop: "1vw",
    justifyContent: "center",
    alignItems: "center",
    gap: "1vw",
  },
  CardPoint1: {
    background: "#CBD5E1",
    borderRadius: "50vw",
    cursor: "pointer",
  },
  CardPoint2: {
    width: "12px",
    height: "12px",
    background: "#CBD5E1",
    borderRadius: "50%",
  },
  CardPoint3: {
    width: "16px",
    height: "16px",
    background: "#CBD5E1",
    borderRadius: "50%",
  },
  CardPointMid: {
    width: "24px",
    height: "24px",
    background: "#FFFFFF",
    borderRadius: "50%",
  },
  modalMainContainer: {
    background: "rgba(255, 255, 255, 0.35)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed" as "fixed",
    zIndex: 1000,
    height: "100%",
  },
  modalContainer: {
    backgroundColor: "white",
    boxShadow:
      "0px 25px 50px 0px rgba(0, 0, 0, 0.09), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 8px 32px 0px rgba(0, 0, 0, 0.06)",
  },
  modalHeaderContainer: {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  modalHeading: {
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#000000",
  },
  modalCloseIcon: { width: "24px", height: "24px" },
  cursorPointer: { cursor: "pointer" },
  modalBodyContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "2vw",
    paddingTop: "0.8vw",
  },
  profitText: {
    fontFamily: "DIN Next LT Arabic Regular",
    color: "#0F172A",
  },
  modalInputLabel: {
    fontFamily: "DIN Next LT Arabic Bold",
    color: "#64748B",
    fontWeight: 700,
    padding: "0",
  },
  modalInputText: {
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row" as "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    border: "0.015vw solid #1B4FE4",
    backgroundColor: "#fff",
    color: "#1B4FE4",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    textTransform: "capitalize" as "capitalize",
  },
  buttonClr: {
    border: "0.015vw solid #1B4FE4",
    color: "#fff",
    fontWeight: 700,
    fontFamily: "DIN Next LT Arabic Bold",
    backgroundColor: "#1B4FE4",
    textTransform: "capitalize" as "capitalize",
  },
  investmentStyle: {
    border: "0.1vw solid #CBD5E1",
  },
  investmentSelect: {
    backgroundColor: "#EFF6FF",
    width: "fit-content",
    fontWeight: "500",
  },
  investmentAmount: {
    border: "none",
    outline: "none",
  },
  modalReturnInput: {
    border: "0.1vw solid #CBD5E1",
    width: "100%",
  },
};

export const LandingPageWeb = withTranslation()(LandingPage)
// Customizable Area End
